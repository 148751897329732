<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import SelectButton from 'primevue/selectbutton'
import { computed, ref, type Ref } from 'vue'
import { useDishesStore } from '@/stores/dishesStore'
import { watch } from 'vue'
import StarterIcon from '@/assets/icons/MenuFilters/StarterIcon.vue'
import MainDishIcon from '@/assets/icons/MenuFilters/MainDishIcon.vue'
import DessertIcon from '@/assets/icons/MenuFilters/DessertIcon.vue'
import SelectWithIcon from '@/components/BaseComponents/SelectWithIcon.vue'
import { usePlanningStore } from '@/stores/planningStore'

interface DishTypeOption {
  name: string;
  value: 'starter' | 'main' | 'dessert';
  icon?: any
}

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
// const dishesStore = ref(useDishesStore());
const planningStore = ref(usePlanningStore());

// function getDefaultDishType(): DishTypeOption {
//   const defaultDishType = planningStore.value.dishToPlan?.dishType || 'main';
//   return {
//     name: t(`creationFilters.dishType.${defaultDishType}`),
//     value: defaultDishType as 'starter' | 'main' | 'dessert'
//   };
// }

const selectedDishType: Ref<DishTypeOption | null> = ref(null);

const toggleSelection = (option: DishTypeOption) => {
  if (planningStore.value.dishToPlan) {
    planningStore.value.dishToPlan.dishType = option.value;
  } else if (planningStore.value.dishTitleToPlan) {
    planningStore.value.dishTitleToPlan.dishType = option.value;
  } else {
    return;
  }
  selectedDishType.value = option;
};

const options: DishTypeOption[] = [
  { name: t('creationFilters.dishType.starter'), value: 'starter', icon: StarterIcon },
  { name: t('creationFilters.dishType.main'), value: 'main', icon: MainDishIcon },
  { name: t('creationFilters.dishType.dessert'), value: 'dessert', icon: DessertIcon },
];


watch(selectedDishType, (newValue) => {
  if (planningStore.value.dishToPlan) {
    planningStore.value.dishToPlan.dishType = newValue?.value;
  } else if (planningStore.value.dishTitleToPlan) {
    planningStore.value.dishTitleToPlan.dishType = newValue?.value;
  } else {
    return;
  }
}, { immediate: true });
</script>

<template>
  <CreationFilter wrapperClass="pt-0">
    <template #title>{{ t('creationFilters.dishType.label') }}</template>
    <template #content>
      <div class="grid grid-cols-3 gap-4">
        <div v-for="option in options" :key="option.value">
          <SelectWithIcon :selected="selectedDishType?.value === option.value" label-h="2"
            @handle-select="() => toggleSelection(option)">
            <template #icon>
              <component :is="option.icon" />
            </template>
            <template #label>{{ option.name }}</template>
          </SelectWithIcon>
        </div>
      </div>
    </template>
  </CreationFilter>
</template>
