import { createRouter, createWebHistory, type RouteLocationNormalized } from 'vue-router'
import { useUserStore } from '@/stores/useUserStore'
import adminRoutes from '@/router/adminRoutes'
import authRoutes from '@/router/authRoutes'
import homeRoutes from '@/router/homeRoutes'
import loadingRoutes from '@/router/loadingRoutes'
import menuRoutes from '@/router/menuRoutes'
import ordersRoutes from '@/router/ordersRoutes'
import planningRoutes from '@/router/planningRoutes'
import profileRoutes from '@/router/profileRoutes'
import searchRoutes from '@/router/searchRoutes'
import { useAppStore } from '@/stores/appStore'
import { useRouterStore } from '@/stores/routerStore'
import HomeView from '@/views/HomeView.vue'
import myRecipesRoutes from '@/router/myRecipesRoutes'
import suppliersRoutes from '@/router/suppliersRoute'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...adminRoutes,
    ...authRoutes,
    ...homeRoutes,
    ...loadingRoutes,
    ...menuRoutes,
    ...ordersRoutes,
    ...planningRoutes,
    ...profileRoutes,
    ...searchRoutes,
    ...myRecipesRoutes,
    ...suppliersRoutes,
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: HomeView },
  ],
  scrollBehavior(to) {
    if (to.name !== 'generation-history')
      return {
        top: 0,
        // behavior: 'smooth',
      }
  },
})

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  console.log('from : ', from)
  console.log('to : ', to)

  if (!to.meta.footerConfig || !to.meta.headerConfig || !to.meta.securityConfig) {
    console.log('missing meta')
    return { name: 'home' }
  }

  const userStore = useUserStore()
  await userStore.initUser()
  if (userStore.user) {
    // Conditionally update the restaurant information
    await userStore.updateRestaurantInfo()
  }

  if (to.meta.securityConfig.requireAuthentication) {
    if (userStore.user) {
      if (to.meta.securityConfig.rolesAllowed.includes(userStore.user.role)) {
        console.log('User authorized')
        useAppStore().appReady = true
        return true
      }
      console.log('User not authorized')
      useAppStore().appReady = true
      return { name: 'home' }
    } else {
      console.log('User not logged in')
      useAppStore().appReady = true
      return { name: 'login' }
    }
  } else {
    if (
      (to.name === 'login' || to.name === 'register' || to.name === 'forgot-password') &&
      userStore.user
    ) {
      useAppStore().appReady = true
      return { name: 'home' }
    } else {
      useAppStore().appReady = true
      return true
    }
  }
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  useRouterStore().previousPath = from.path
})

export default router
