import { setLocale } from '@/i18n'
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
export const useAppStore = defineStore('app', () => {
  const appReady: Ref<boolean> = ref(false)
  const language: Ref<'fr' | 'en' | 'de' | 'it'> = ref(localStorage.getItem('language') as ('fr' | 'en' | 'de' | 'it') || 'en')

  function setLanguage(newLanguage: 'fr' | 'en' | 'de' | 'it') {
    setLocale(newLanguage);
    language.value = newLanguage
    localStorage.setItem('language', newLanguage)
  }

  return { appReady, language, setLanguage }
})
