<script setup lang="ts">
import ChevronLeftIcon from '@/assets/icons/Planning/ChevronLeftIcon.vue'
import ChevronRightIcon from '@/assets/icons/Planning/ChevronRightIcon.vue'
import { useAuth } from '@/composables/useAuth'
import { useCategories } from '@/composables/useCategories'
import type { MessageSchema } from '@/i18n'
import { usePlanningStore } from '@/stores/planningStore'
import { type Dish, type Ingredient } from '@/types/dish'
import GenerationHistoryView from '@/views/MyRecipes/GenerationHistoryView.vue'
import MyRecipesView from '@/views/MyRecipes/MyRecipesView.vue'
import Sidebar from 'primevue/sidebar'
import { computed, ref, watch, type ModelRef } from 'vue'
import { useI18n } from 'vue-i18n'
import MyRecipesInDialogView from '../Menus/MenuDishViewComponents/PlanDishFromDialog/MyRecipesInDialog.vue'
import MyScansView from '@/views/MyRecipes/MyScansView.vue'
import PlannedView from '@/views/MyRecipes/PlannedView.vue'
import DishTagsView from '@/views/MyRecipes/DishTagsView.vue'
import TagView from '@/views/MyRecipes/TagView.vue'
import FavoritesView from '@/views/MyRecipes/FavoritesView.vue'
import DishTagsInDialog from '../Menus/MenuDishViewComponents/PlanDishFromDialog/DishTagsInDialog.vue'
import Button from 'primevue/button'
import GeneratedTitlesView from '@/views/MyRecipes/GeneratedTitlesView.vue'
import DishCreationFormView from '@/views/Profile/DishCreationFormView.vue'
import MenuFiltersView from '@/views/Menus/MenuFiltersView.vue'
import MenuGenerationResultsView from '@/views/Menus/MenuGenerationResultsView.vue'
import { useDishesStore } from '@/stores/dishesStore'

const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const showSideBar: ModelRef<boolean | undefined> = defineModel('visible', { required: true })

  const planningStore = usePlanningStore()

const emit = defineEmits<{
  (e: 'addDishToPlan', dish: Dish): void
  (e: 'addDishTitleToPlan', dishTitle: Dish): void
}>()

const category = ref('')
const subCategory = ref('') // For tag subcategories
const showTagView = ref(false);

const currentView = ref<'filters' | 'generating' | 'results'>('filters')
const dishesStore = ref(useDishesStore())

const props = defineProps<{
  // ingredients: Ingredient[]
  // category: 'history' | 'favorites'
  plannedMenuName: string
}>()

watch(showSideBar, (newValue) => {
  if (!newValue) {
    planningStore.choosingDishToPlan = false
    currentView.value = 'filters'
    dishesStore.value.resetGenerationParams()
  }
})

function addDishToPlan(dish: Dish) {
  dishesStore.value.cleanGenerationParams()
  if (!dish) return
  emit('addDishToPlan', dish)
}

function goBack() {
  if (subCategory.value) {
    // If there's a subcategory, clear it and go back to tags view
    subCategory.value = ''
    showTagView.value = true
  } else if (category.value) {
    // If there's only a category, clear it and go back to main view
    category.value = ''
    showTagView.value = false
  }
}

function handleStartGeneration() {
  currentView.value = 'generating'
}

function handleGenerationComplete() {
  currentView.value = 'results'
}

function handleDishSelect(dish: Dish) {
  dishesStore.value.cleanGenerationParams()
  planningStore.choosingDishToPlan = false
  addDishToPlan(dish)
  showSideBar.value = false
}

function handleTitleSelect(title: string) {
  dishesStore.value.cleanGenerationParams() 
  planningStore.choosingDishToPlan = false
  const dishTitle = { name: title } as Dish;
  emit('addDishTitleToPlan', dishTitle);
  showSideBar.value = false;
}

</script>
<template>
  <Sidebar v-model:visible="showSideBar" :header="t('planning.plannedMenu.generateDishFromPlan', { plannedMenuName: props.plannedMenuName })" position="bottom" style="height: 90%"
    :block-scroll="true">
    

    <MenuFiltersView v-if="currentView === 'filters'" @start-generation="handleStartGeneration"
      @generation-complete="handleGenerationComplete" />

    <!-- <div v-else-if="currentView === 'generating'" class="flex flex-col items-center justify-center">
      <i class="pi pi-spin pi-spinner text-primary-500" style="font-size: 2rem"></i>
      <p class="mt-4">{{ t('common.generating') }}</p>
    </div> -->
    <MenuGenerationResultsView v-else class="in-dialog" @select-dish="handleDishSelect"
      @select-title="handleTitleSelect" />
  </Sidebar>
</template>
