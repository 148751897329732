<script setup lang="ts">
import { ref, watch, computed } from 'vue'

const props = withDefaults(
  defineProps<{
    placeholder?: string
    modelValue?: number | ''
    inputId?: string
    min?: number
    max?: number
    showButtons?: boolean
    class?: string
    step?: number
    invalid?: boolean
    disabled?: boolean
  }>(),
  {
    step: 1,
    modelValue: 0,
    showButtons: false,
    invalid: false,
    disabled: false,
  }
)

const internalValue = ref<number | ''>(props.modelValue === '' ? 0 : props.modelValue)

const emit = defineEmits(['update:modelValue'])

const updateValue = () => {
  internalValue.value === ''
    ? emit('update:modelValue', 0)
    : emit('update:modelValue', internalValue.value)
}

watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue === '' ? 0 : newValue
  }
)

const increment = () => {
  if (internalValue.value === '') {
    internalValue.value = 1
  } else if (props.max === undefined || internalValue.value < props.max) {
    internalValue.value += 1
    updateValue()
  }
}

const decrement = () => {
  if (internalValue.value === '') {
    return
  } else if (props.min === undefined || internalValue.value > props.min) {
    internalValue.value -= 1
    updateValue()
  }
}

const propsClasses = computed(() => {
  return props.class ? props.class.split(' ').map((c) => `!${c}`) : []
})
const inputClass = computed(() => [
  'flex sm:text-sm w-full m-0 py-1.5 px-3 text-surface-900 dark:text-surface-0 placeholder:text-surface-400 dark:placeholder:text-surface-500',
  'bg-surface-0 dark:bg-surface-900 outline-none focus:ring-primary-500 dark:focus:ring-primary-500 focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-inset focus:ring-primary-600 dark:focus:ring-primary-500',
  props.showButtons
    ? 'rounded-md rounded-tr-none rounded-br-none rounded-tl-none rounded-bl-none order-2 border-x'
    : 'rounded-md border',
  props.invalid
    ? 'border-red-500 dark:border-red-500'
    : 'border-surface-300 dark:border-surface-700',
  props.disabled ? 'opacity-60 select-none pointer-events-none cursor-default' : '',
])

const buttonClass = computed(() => [
  'flex items-center justify-center text-center align-bottom relative text-surface-600 dark:text-surface-400 bg-surface-0 dark:bg-surface-800',
  'w-[3rem] px-2.5 py-1.5 focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary-600 dark:focus:ring-primary-500 hover:bg-surface-50 dark:hover:bg-surface-700 cursor-pointer select-none',
])

const buttonDecrementClass = computed(() => [
  ...buttonClass.value,
  'rounded-md rounded-tr-none rounded-br-none order-1',
])

const buttonIncrementClass = computed(() => [
  ...buttonClass.value,
  'rounded-md rounded-bl-none rounded-tl-none order-3',
])
</script>

<template>
  <template v-if="props.showButtons">
    <span
      class="inline-flex rounded-md shadow-sm ring-1 ring-surface-300 dark:ring-surface-700 ring-offset-0"
      :class="propsClasses"
      data-pc-name="inputnumber"
    >
      <button
        @click="decrement"
        :class="buttonDecrementClass"
        type="button"
        aria-hidden="true"
        tabindex="-1"
        :disabled="disabled"
      >
        <span class="pi pi-minus"></span>
      </button>
      <input
        type="number"
        :id="inputId"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        v-model="internalValue"
        @input="updateValue"
        :class="[...inputClass, ...propsClasses]"
        inputmode="numeric"
        :step="step"
        :disabled="disabled"
      />
      <button
        @click="increment"
        :class="buttonIncrementClass"
        type="button"
        aria-hidden="true"
        tabindex="-1"
        :disabled="disabled"
      >
        <span class="pi pi-plus"></span>
      </button>
    </span>
  </template>

  <template v-else>
    <input
      type="number"
      :step="step"
      :id="inputId"
      :placeholder="placeholder"
      :min="min"
      :max="max"
      v-model="internalValue"
      @input="updateValue"
      :class="[...inputClass, ...propsClasses]"
      inputmode="numeric"
      :disabled="disabled"
    />
  </template>
</template>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
