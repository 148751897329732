import axios from 'axios'
// import FormData from 'form-data'
import { useEnvStore } from '@/stores/envStore'
import { useFetch } from '@/composables/useFetch'
// import { useFetch } from '@/composables/useFetch'

export async function generateImage(dishId: number) {
  const apiUrl = useEnvStore().apiUrl
  const { fetchData } = useFetch();


  try {
    const { data, error } = await fetchData(`${apiUrl}/generate/images/${dishId}`, 'GET')
    if (error) throw error
    return { data, error: null }
  } catch (error) {
    console.error('Error in generateImage:', error)
    return { data: null, error }
  }

    // const promptResponse = await axios.get(`${apiUrl}/generate/images/${dishId}/prompt`)
  //   const prompts = promptResponse.data.message.response


  //   const requestBodyGastro = createRequestBody(prompts.gastro, 'realistic_image')
  //   const requestBodyBistro = createRequestBody(prompts.bistro, 'realistic_image')
  //   // const formDataGastro = toFormData(prompts.gastro, 'jpeg', model)
  //   // const formDataBistro = toFormData(prompts.bistro, 'jpeg', model)

  //   const [imageGastro, imageBistro] = await Promise.all([
  //     axios.post(`${imgGenApiUrl}`, requestBodyGastro, {
  //       headers: { Authorization: `Bearer ${imgGenApiKey}` },
  //     }),
  //     axios.post(`${imgGenApiUrl}`, requestBodyBistro, {
  //       headers: { Authorization: `Bearer ${imgGenApiKey}` },
  //     }),
  //   ])

  //   const base64ImageGastro = imageGastro.data.data[0].b64_json
  //   const base64ImageBistro = imageBistro.data.data[0].b64_json
  //   const imageBlobGastro = base64ToBlob(base64ImageGastro, 'image/jpeg')
  //   const imageBlobBistro = base64ToBlob(base64ImageBistro, 'image/jpeg')

  //   axios.defaults.withCredentials = false
  //   // Handle the response, save or process images
  //   const fileNameGastro = generateFileName(dishId, 'gastro', 'jpeg')
  //   const fileNameBistro = generateFileName(dishId, 'bistro', 'jpeg')
  //   const backendFormData = new FormData()
  //   backendFormData.append(
  //     'imageGastro',
  //     new Blob([imageGastro.data], { type: 'image/jpeg' }),
  //     fileNameGastro
  //   )
  //   backendFormData.append(
  //     'imageBistro',
  //     new Blob([imageBistro.data], { type: 'image/jpeg' }),
  //     fileNameBistro
  //   )
  //   // backendFormData.append('type', dishType);
  //   // fs.writeFileSync(fileName, Buffer.from(response.data));

  //   const { fetchData } = useFetch()
  //   const { data, error } = await fetchData(
  //     `${useEnvStore().apiUrl}/dishes/${dishId}/images`,
  //     'POST',
  //     backendFormData
  //   )

  //   if (error) {
  //     throw new Error(`Failed to send image to backend: ${error.message}`)
  //   } else {
  //     console.log('Image sent to backend successfully:', data)
  //     return { data, error: null }
  //   }
  // } catch (error) {
  //   console.error('Failed to generate images:', error)
  //   return { data: null, error }
  //   throw new Error('Image generation failed')
  // } finally {
  //   axios.defaults.withCredentials = false
  // }
}

// function createRequestBody(prompt, style = 'realistic_image', n = 1) {
//   return {
//     prompt: prompt,
//     style: style,
//     n: n,
//     response_format: 'b64_json',
//   }
// }

// function base64ToBlob(base64Data, contentType = '') {
//   const byteCharacters = atob(base64Data)
//   const byteArrays = []

//   for (let offset = 0; offset < byteCharacters.length; offset += 512) {
//     const slice = byteCharacters.slice(offset, offset + 512)
//     const byteNumbers = new Array(slice.length)
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i)
//     }
//     const byteArray = new Uint8Array(byteNumbers)
//     byteArrays.push(byteArray)
//   }

//   return new Blob(byteArrays, { type: contentType })
// }
// function toFormData(prompt, contentType, aiModel: string = 'sd3.5-large') {
//   const formData = new FormData()
//   formData.append('prompt', prompt)
//   formData.append('output_format', contentType)
//   formData.append('model', aiModel)
//   return formData
// }

// function generateFileName(dishId, type, extension) {
//   const date = new Date()
//   const formattedDate = date.toISOString().replace(/[-:.]/g, '').slice(0, 15) // Format date as 'YYYYMMDDHHMMSS'
//   const fileName = `gstr-img-${type}-${dishId}-${formattedDate}.${extension}`

//   return fileName
// }
