import type { Dish, Ingredient } from '@/types/dish'

function calculateDishPrice(dish: Dish, numberOfPeople: number = 1) {
  const pricePerPerson = dish.ingredients.reduce(
    (total, ingredient) => total + (ingredient.price ? ingredient.price : 0),
    0
  )
  const priceTotal = pricePerPerson * numberOfPeople
  return parseFloat(priceTotal.toFixed(2))
}

function calculateIngredientPrice(price: number | undefined, numberOfPeople: number = 1) {
  if (!price || price == 0) return 0
  // console.log("calculate Ingredient price, ", price)
  const pricePerPerson = price
  const priceTotal = pricePerPerson * numberOfPeople
  // console.log("pricePerPerson, priceTotal ", pricePerPerson, priceTotal)
  return parseFloat(priceTotal.toFixed(2))
}

function calculateIngredientQuantity(quantity: number | undefined, numberOfPeople: number = 1) {
  if (!quantity || quantity == 0) return 0
  const quantityPerPerson = quantity

  const quantityTotal = quantityPerPerson * numberOfPeople
  return parseFloat(quantityTotal.toFixed(2))
}

function adjustUnitsForQuantity(
  qtyPerPerson: number,
  unit: string,
  numberOfPeople: number = 1
): { quantity: number; unit: string } {
  if (!qtyPerPerson) return { quantity: qtyPerPerson, unit: unit }
  if (!unit) return { quantity: Math.round(qtyPerPerson * numberOfPeople * 100) / 100, unit: unit }
  const quantityAdjusted = qtyPerPerson * numberOfPeople

  if (unit === 'g') {
    if (quantityAdjusted >= 1000) {
      return { quantity: Math.round((quantityAdjusted / 1000) * 100) / 100, unit: 'kg' }
    }
    return { quantity: Math.round(quantityAdjusted), unit: 'g' }
  }
  if (unit === 'kg' && quantityAdjusted < 1) {
    return { quantity: Math.round(quantityAdjusted * 1000 * 100) / 100, unit: 'g' }
  }
  if (unit === 'ml' && quantityAdjusted >= 1000) {
    return { quantity: Math.round((quantityAdjusted / 1000) * 100) / 100, unit: 'l' }
  } else if (unit === 'ml' && quantityAdjusted >= 100) {
    return { quantity: Math.round((quantityAdjusted / 100) * 100) / 100, unit: 'dl' }
  } else if (unit === 'ml') {
    return { quantity: Math.round(quantityAdjusted), unit: 'ml' }
  } else if (unit === 'dl' && quantityAdjusted < 1) {
    return { quantity: Math.round(quantityAdjusted * 100 * 100) / 100, unit: 'ml' }
  } else if (unit === 'dl' && quantityAdjusted > 10) {
    return { quantity: Math.round((quantityAdjusted / 10) * 100) / 100, unit: 'l' }
  } else if (unit === 'l' && quantityAdjusted < 0.1) {
    return { quantity: Math.round(quantityAdjusted * 1000 * 100) / 100, unit: 'ml' }
  } else if (unit === 'l' && quantityAdjusted < 1) {
    return { quantity: Math.round(quantityAdjusted * 10 * 100) / 100, unit: 'dl' }
  }
  return { quantity: Math.round(quantityAdjusted * 100) / 100, unit }
}

function convertUnit(quantity, currentUnit, unitToConvertTo) {
  let conversionFactor = 1 // Default to 1 if no conversion is needed

  // Converting weights
  if (currentUnit === 'kg' && unitToConvertTo === 'g') {
    conversionFactor = 1000
  } else if (currentUnit === 'g' && unitToConvertTo === 'kg') {
    conversionFactor = 0.001
  }

  // Converting volumes
  if (currentUnit === 'l' && unitToConvertTo === 'ml') {
    conversionFactor = 1000
  } else if (currentUnit === 'ml' && unitToConvertTo === 'l') {
    conversionFactor = 0.001
  } else if (currentUnit === 'dl' && unitToConvertTo === 'ml') {
    conversionFactor = 100
  } else if (currentUnit === 'ml' && unitToConvertTo === 'dl') {
    conversionFactor = 0.01
  } else if (currentUnit === 'cl' && unitToConvertTo === 'ml') {
    conversionFactor = 10
  } else if (currentUnit === 'ml' && unitToConvertTo === 'cl') {
    conversionFactor = 0.1
  }

  return quantity * conversionFactor
}

export {
  calculateDishPrice,
  calculateIngredientPrice,
  calculateIngredientQuantity,
  adjustUnitsForQuantity,
  convertUnit,
}
