import type { ToastServiceMethods } from 'primevue/toastservice'
import { useFetch } from './useFetch'
import { useEnvStore } from '@/stores/envStore'
import i18n from '@/i18n'
import type { User } from '@/types/api'

const { fetchData } = useFetch()
const { t } = i18n.global

export function useProfileProfile(toast: ToastServiceMethods) {
  async function updateUserProperty(
    propertyName: string,
    value: string | number
  ): Promise<User | null> {
    const url = useEnvStore().apiUrl + '/users'
    const { error, data } = await fetchData(url, 'PATCH', { [propertyName]: value })

    if (error) {
      console.log(error)
      if (error.details.message) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: error.details.message,
          life: 3000,
        })
      } else {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('profile.profile.restaurantTab.updateError'),
          life: 3000,
        })
      }
      return null
    }
    // toast.add({
    //   severity: 'success',
    //   summary: t('common.success'),
    //   detail: t('profile.profile.restaurantTab.updateSuccess'),
    //   life: 3000,
    // })

    return data as User
  }

  async function updatePassword(currentPassword: string, newPassword: string, confirmPassword: string): Promise<boolean> {
    const url = useEnvStore().apiUrl + '/user/password'

    const payload = {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    }
    const { error } = await fetchData(url, 'PUT', payload)

    // const error = false
    if (error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: error.details.message || t('profile.profile.profileTab.passwordChangeError'),
        life: 3000,
      })
      return false
    } else {
      toast.add({
        severity: 'success',
        summary: t('common.success'),
        detail: t('profile.profile.profileTab.passwordChangeSuccess'),
        life: 3000,
      })
      // Refresh user data after password change
      
      // log out the user after password change
      //  await useAuth().logout()
      // refresh the page
      // router.push({ name: 'login' })
      return true
    }
  }

  return { updateUserProperty, updatePassword }
}
