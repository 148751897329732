<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { ref } from 'vue'
import Divider from 'primevue/divider'
import InlineLink from '@/components/InlineLink.vue'
import InlineMessage from '@/components/InlineMessage.vue'
import { useAuth } from '@/composables/useAuth.js'

const email = ref('')

const { sendResetLink, errors, isFetching } = useAuth()
errors.value = {}

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

function hasError(fieldName: string) {
  return !!errors.value[fieldName] && errors.value[fieldName].length > 0
}
</script>

<template>
  <main>
    <form @submit.prevent="sendResetLink(email)">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <label for="email">{{ t('auth.user.form.label.mail') }}</label>
          <div>
            <InputText
              type="email"
              id="email"
              class="w-full"
              v-model="email"
              :invalid="hasError('email')"
              :placeholder="t('auth.user.form.placeholder.mail')"
            />
          </div>
          <InlineMessage severity="error" v-if="hasError('email')">
            {{ errors.email[0] }}
          </InlineMessage>
        </div>

        <Button
          :loading="isFetching"
          :disabled="isFetching"
          :label="t('auth.forgotPassword.send')"
          class="w-full mt-8"
          size="large"
          type="submit"
        />
      </div>
    </form>
    <Divider />
    <p><InlineLink destination="/login">{{ t('auth.forgotPassword.backToLogin')}}</InlineLink></p>
  </main>
</template>
