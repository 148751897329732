<script setup lang="ts">
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import Textarea from 'primevue/textarea'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import { onMounted, type Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import axios from '@/utils/axios'
import { useEnvStore } from '@/stores/envStore'
import type { Supplier } from '@/types/api'
import type { Ingredient } from '@/types/dish'
import { useOrderList } from '@/stores/orderStore.js'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import { useFetch } from '@/composables/useFetch.js'
import EditIngredient from '@/components/Orders/EditIngredient.vue'
import { useUserStore } from '@/stores/useUserStore'

const orderStore = useOrderList()
const ingredientsToOrder = orderStore.ingredientsToOrder
const { t } = useI18n<{ message: MessageSchema }>()
const user = ref(useUserStore().user)
const toast = useToast()
const router = useRouter()
const { isFetching, fetchData } = useFetch()

const supplierList: Ref<Supplier[]> = ref([])
const ingredientToEdit: Ref<Ingredient | null> = ref(null)
const showSidebar = ref(false)
const selectedSupplierUuid: Ref<string | null> = ref(null)
const wantedDate = ref(new Date())
const customMessage = ref('')

async function order() {
  if (user.value) {
    const restaurantId = user.value?.restaurants[0].id
    const { data, error } = await fetchData(
      useEnvStore().apiUrl + '/restaurants/' + restaurantId + '/order',
      'POST',
      {
        ingredients: ingredientsToOrder,
        wantedDate: wantedDate.value,
        customMessage: customMessage.value,
        supplierUuid: selectedSupplierUuid.value,
      }
    )

    if (error) {
      toast.add({
        severity: 'error',
        summary: t('orders.orderForm.order.errorSummary'),
        detail: t('orders.orderForm.order.errorDetail'),
        life: 3000,
      })
    } else if (data) {
      toast.add({
        severity: 'success',
        summary: t('orders.orderForm.order.successSummary'),
        detail: t('orders.orderForm.order.successDetail'),
        life: 3000,
      })

      await orderStore.fetchIngredients(restaurantId)
      router.push({ name: 'orders' })
    }
  }
}

async function fetchSuppliers() {
  if (user.value) {
    const restaurantId = user.value?.restaurants[0].id
    try {
      const response = await axios.get(
        `${useEnvStore().apiUrl}/restaurants/${restaurantId}/suppliers`
      )
      supplierList.value = response.data
    } catch (error) {
      console.error('Erreur lors du chargement des fournisseurs', error)
    }
  }
}

function addOrUpdateIngredient(ingredient: Ingredient | null) {
  console.log('addOrUpdateIngredient', ingredient)
  if (ingredient) {
    ingredientToEdit.value = ingredient
  } else {
    ingredientToEdit.value = { id: -1, category: '', name: '', quantity: 0, unit: '', price: 0 }
  }
  showSidebar.value = true
}

function addIngredient() {
  if (!ingredientToEdit.value) return
  if (ingredientToEdit.value.name) {
    // De base là il sera à -1, on le set à -2 pour que le bouton supprimer s'affiche dans la modal
    ingredientToEdit.value.id = -2
    ingredientsToOrder.push(ingredientToEdit.value)
    showSidebar.value = false // Close the sidebar after adding
  } else {
    console.error('Ingredient data is incomplete.')
  }
}

function updateIngredient() {
  if (!ingredientToEdit.value) return
  if (ingredientToEdit.value?.id !== -1 && ingredientToEdit.value?.name) {
    const index = ingredientsToOrder.findIndex((ing) => ing.id === ingredientToEdit.value?.id)
    if (index !== -1) {
      ingredientsToOrder[index] = ingredientToEdit.value
    }
    showSidebar.value = false // Close the sidebar after editing
  } else {
    console.error('Ingredient data is incomplete or invalid ID.')
  }
}

function deleteIngredients() {
  if (!ingredientToEdit.value) return
  if (ingredientToEdit.value?.id !== -1) {
    const index = ingredientsToOrder.findIndex((ing) => ing.id === ingredientToEdit.value?.id)
    if (index !== -1) {
      ingredientsToOrder.splice(index, 1)
    }
    showSidebar.value = false // Close the sidebar after deleting
  } else {
    console.error('Invalid ingredient ID for deletion.')
  }
}

onMounted(() => {
  fetchSuppliers()
})
</script>

<template>
  <main class="space-y-4">
    <div class="p-3 mb-3 bg-primary-100 border border-primary-400 text-primary-700 rounded">
      <p><i class="pi pi-info-circle"></i> {{ t('common.developmentNotice') }}</p>
    </div>
    <div>
      <h3>{{ t('orders.orderForm.supplier') }}</h3>
      <Dropdown v-model="selectedSupplierUuid" :options="supplierList.filter((s) => s.email)" optionLabel="name"
        option-value="uuid" :placeholder="t('orders.orderForm.supplierPlaceholder')" class="w-full" />
    </div>

    <div>
      <h3>{{ t('orders.orderForm.wantedDate') }}</h3>
      <Calendar v-model="wantedDate" class="w-full" />
    </div>

    <div>
      <h3>{{ t('orders.orderForm.customMessage') }}</h3>
      <Textarea v-model="customMessage" class="w-full" />
    </div>

    <div>
      <h3>{{ t('orders.orderForm.shippingList') }}</h3>
      <DataTable :value="ingredientsToOrder" @row-click="(evt) => addOrUpdateIngredient(evt.data)">
        <Column field="quantity" :header="t('orders.orderForm.quantityShort')" />
        <Column field="unit" :header="t('orders.orderForm.unit')" />
        <Column field="name" :header="t('orders.orderForm.name')" />
      </DataTable>
      <div class="hover:cursor-pointer" @click="addOrUpdateIngredient(null)">
        <p>+ {{ t('profileFavorites.creationForm.addIngredientButton') }}</p>
      </div>
    </div>

    <div>
      <Button v-if="false" :label="t('orders.orderForm.order.orderLabel')" @click="order" class="w-full"
        :disabled="!selectedSupplierUuid || !wantedDate || ingredientsToOrder.length === 0" :loading="isFetching" />
      <Button :label="`${t('orders.orderForm.order.orderLabel')} (${t('common.inDevelopment')})`" @click="null"
        class="w-full" :loading="isFetching" disabled />
    </div>

    <EditIngredient v-model:ingredient="ingredientToEdit" v-model:visible="showSidebar" @add-ingredient="addIngredient"
      @edit-ingredient="updateIngredient" @delete-ingredient="deleteIngredients" />
  </main>
</template>
