<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { ref, watch } from 'vue'
import DayMenuView from './DayMenuView.vue'

const props = defineProps<{
  weekRange: {
    start: Date;
    end: Date;
  };
}>()
const isLoadingWeekDays = ref(false);

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const weekDays = ref<Date[]>([]);


function getWeekDays(startDate: Date, endDate: Date): Date[] {
  isLoadingWeekDays.value = true;
  const days: Date[] = []; // Explicitly define the array as a Date[] array
  let current = new Date(startDate);
  
  while (current <= endDate) {
    days.push(new Date(current)); // This line now knows that it's pushing a Date
    current.setDate(current.getDate() + 1);
  }
  
  isLoadingWeekDays.value = false;
  return days;
}

weekDays.value = getWeekDays(props.weekRange.start, props.weekRange.end);

watch(() => props.weekRange, (newRange) => {
  weekDays.value = getWeekDays(newRange.start, newRange.end);
}, { deep: true });

</script>

<template>
  <main>
     <div v-if="isLoadingWeekDays" class="flex justify-center items-center h-full w-full">
       <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
     </div>
    <div v-else class="flex flex-col gap-2 overflow-hidden">
      <div v-for="(day, index) in weekDays" :key="index" class="">
        <!-- Render the DayMenuView for each day -->
        <DayMenuView :date="day" />
      </div>
    </div>
  </main>
</template>

<style scoped></style>
