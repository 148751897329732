
<script setup lang="ts">


import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'
import type { ModelRef } from 'vue';
import { useI18n } from 'vue-i18n';
import type { MessageSchema } from '@/i18n';
import { useUserStore } from '@/stores/useUserStore';
import AvatarCross from '@/assets/icons/Avatars/AvatarCross.vue';
const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

// const props = defineProps<{
//   modelValue: boolean
// }>()
const visible: ModelRef<boolean | undefined> = defineModel('visible', { required: true })

const router = useRouter()

function contactSupport() {
  router.push({ name: 'support' }) // Adjust route name as needed
}

function handleConfirm() {
  useUserStore().confirmNoMoreCreditDialog();
  visible.value = false
}

function handleUpdateCredits() {
  // userStore.confirmCreditWarning()
  // TODO : handle update credits
  useUserStore().confirmNoMoreCreditDialog();
  visible.value = false
}
</script>
<template>
  <Dialog v-model:visible="visible" modal :header="t('credits.noMoreCredit.title')" :style="{ width: '25rem' }">
    <AvatarCross class="w-32 my-4 mx-auto" />
    <p>{{ t('credits.noMoreCredit.message') }}</p>
    <!-- <div class="flex flex-col gap-4">
    </div> -->
    <template #footer>
      <Button :label="t('common.ok')" @click="handleConfirm()" />
      <!-- <Button :label="t('')" @click="contactSupport()" /> -->
    </template>
  </Dialog>
</template>