<template>
  <div class="card-container w-full p-6 rounded-xl bg-primary-100 relative shadow-clickable-card corner-fold">
    <slot />
  </div>
</template>

<style scoped>
.card-container {
  position: relative;
  overflow: hidden;
}
</style>
