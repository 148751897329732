<script setup lang="ts">
import HistoryIcon from '@/assets/icons/Profile/HistoryIcon.vue'
import { computed, markRaw, onMounted, ref, type Ref } from 'vue'
import HeartEmpty from '@/assets/icons/2States/HeartEmpty.vue'
import ScanIcon from '@/assets/icons/Profile/ScanIcon.vue'
// import { useI18n } from 'vue-i18n'
// import type { MessageSchema } from '@/i18n/index.js'
import MyRecipesMenuItem from '@/components/MyRecipes/MyRecipesMenuItem.vue'
import PlanningIcon from '@/assets/icons/Planning/PlanningIcon.vue'
import GeneratedTitlesIcon from '@/assets/icons/Planning/GeneratedTitlesIcon.vue'
import Button from 'primevue/button'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import { useToast } from 'primevue/usetoast'
import { useDishesStore } from '@/stores/dishesStore'
import TagIcon from '@/assets/icons/Menus/TagIcon.vue'
import { usePlanningStore } from '@/stores/planningStore'
import { useUserStore } from '@/stores/useUserStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

// const myDishTags: Ref<DishTag[] | []> = ref([])
const toast = useToast()
const user = ref(useUserStore().user)
const dishesStore = useDishesStore();
const restaurantId = user.value?.restaurants[0]?.id || null
const planningStore = usePlanningStore()




const links = [
  {
    name: t('homeView.myRecipes.history'),
    icon: markRaw(HistoryIcon),
    url: '/my-recipes/generation-history',
  },
  {
    name: t('homeView.myRecipes.myCategories'),
    icon: markRaw(TagIcon),
    url: '/my-recipes/tags',
  },
  {
    name: t('homeView.myRecipes.scan'),
    icon: markRaw(ScanIcon),
    url: '/my-recipes/my-scans',
  },
  {
    name: t('homeView.myRecipes.planned'),
    icon: markRaw(PlanningIcon),
    url: '/my-recipes/planned',
  },
  {
    name: t('homeView.myRecipes.suggestions'),
    icon: markRaw(GeneratedTitlesIcon),
    url: '/my-recipes/titles',
  },
]

onMounted(async () => {
  // const route = useRoute()
  await dishesStore.fetchTags(restaurantId, toast);
})
</script>

<template>
  <main>
    <div class="mt-4">
      <MyRecipesMenuItem v-for="(link, index) in links" :key="link.name" :name="link.name" :icon="link.icon"
        :url="link.url" :index="index" />
    </div>
  </main>
</template>
