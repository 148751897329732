<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6.13171H13.2V8.47903H18C19.98 8.47903 21.6 10.0635 21.6 12C21.6 13.9365 19.98 15.521 18 15.521H13.2V17.8683H18C21.312 17.8683 24 15.2393 24 12C24 8.76071 21.312 6.13171 18 6.13171ZM10.8 15.521H6C4.02 15.521 2.4 13.9365 2.4 12C2.4 10.0635 4.02 8.47903 6 8.47903H10.8V6.13171H6C2.688 6.13171 0 8.76071 0 12C0 15.2393 2.688 17.8683 6 17.8683H10.8V15.521ZM7.2 10.8263H16.8V13.1737H7.2V10.8263Z"
      fill="currentColor"
    />
  </svg>
</template>
