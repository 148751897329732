<template>
  <div class="flex flex-row items-center justify-center h-full gap-3 w-full">
    <span>
      <slot name="label" class=" w-2/3"></slot>
    </span>
    <span class="h-2/3">
      <slot name="icon"></slot>
    </span>
  </div>
</template>
