<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { computed, onMounted, ref, type Ref } from 'vue'
import MultiSelect from 'primevue/multiselect'
import { useDishesStore } from '@/stores/dishesStore'
import countries from '@/assets/countries.json'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { Country } from '@/types/country.js'
import { watch } from 'vue'
import { useAppStore } from '@/stores/appStore'
import { useUserStore } from '@/stores/useUserStore'

const dishesStore = ref(useDishesStore())

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const { user } = useUserStore();
const { fetchData } = useFetch()
const appStore = useAppStore();

const selectedCountries = ref([])
const profileContries: Ref<string[]> = ref([])

onMounted(async () => {
  const { data, error } = await fetchData(
    useEnvStore().apiUrl +
      '/restaurants/' +
      user?.restaurants[0].id +
      '/attributes/inspirations'
  )
  if (data) {
    data.forEach((country) => {
      profileContries.value.push(country.name)
    })
  }
})

const orderedCountries = computed(() => {
  const newOrder: Country[] = []

  for (const profileCountry of profileContries.value) {
    const countryToMove = countries.find((country) => country.alpha2 === profileCountry)
    if (countryToMove) newOrder.push(countryToMove)
  }

  const filteredCountry = countries.filter(
    (country) => !profileContries.value.includes(country.alpha2)
  )

  newOrder.sort((a, b) => a.fr.localeCompare(b.fr))
  filteredCountry.sort((a, b) => a.fr.localeCompare(b.fr))

  newOrder.push(...filteredCountry)

  return newOrder
})

function handleCountrySelect(newValues) {
  selectedCountries.value = newValues // Update the local state
  if (newValues.length) {
    dishesStore.value.inspirationSource = 'country'
    dishesStore.value.genParamsInspirationCountry = newValues // Update the global state
  } else {
    dishesStore.value.inspirationSource = 'none'
    dishesStore.value.genParamsInspirationCountry = []
  }
}

// watch(dishesStore.value.generationParams, (newParams) => {
//   console.log("changes", newParams)
// }, {deep: true});
</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.inspirations') }}</template>
    <template #content>
      <MultiSelect
        v-model="dishesStore.genParamsInspirationCountry"
        multiple
        filter
        display="chip"
        :disabled="
          dishesStore.inspirationSource !== 'country' && dishesStore.inspirationSource !== 'none'
        "
        class="w-full"
        :optionLabel="appStore.language"
        optionValue="en"
        :options="orderedCountries"
        :virtualScrollerOptions="{ itemSize: 50 }"
        :pt="{ root: { class: 'h-9 items-center' } }"
        :pt-options="{ mergeProps: true }"
        :placeholder="t('creationFilters.inspirationsDropdownLabel')"
        @update:modelValue="handleCountrySelect"
      />
    </template>
  </CreationFilter>
</template>
