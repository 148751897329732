<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import type { UserRegisterRequest } from '@/types/api'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Stepper from 'primevue/stepper'
import { computed, onMounted, reactive, ref, type Ref } from 'vue'
import InlineLink from '@/components/InlineLink.vue'
import InlineMessage from '@/components/InlineMessage.vue'
import Divider from 'primevue/divider'
import { useAuth } from '@/composables/useAuth.js'
import StepperPanel from 'primevue/stepperpanel'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { Attribute } from '@/types/restaurants.js'
import RadioButton from 'primevue/radiobutton'
import { useToast } from 'primevue/usetoast'
import { useAppStore } from '@/stores/appStore'
import Dropdown from 'primevue/dropdown'

const { register, errors, isFetching } = useAuth()
const { fetchData } = useFetch()
const toast = useToast()
const appStore = useAppStore();


errors.value = {}

const password = ref('')
const passwordConfirmation = ref('')

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const restaurationTypes: Ref<Attribute[]> = ref([])

const userForm: Ref<UserRegisterRequest> = ref({
  email: '',
  password: '',
  password_confirmation: '',
  first_name: '',
  last_name: '',
  restaurant_name: '',
  restauration_type_id: 0,
  language: appStore.language,
})

const activeStep = ref(0)

function hasError(fieldName: string) {
  return !!errors.value[fieldName] && errors.value[fieldName].length > 0
}

const touched = reactive({
  email: false,
  password: false,
  passwordConfirmation: false,
  first_name: false,
  last_name: false,
  restaurant_name: false,
  restauration_type_id: false,
})

const isEmailValid = computed(() => {
  if (!touched.email) return true
  return touched.email && userForm.value.email && isValidEmail(userForm.value.email)
})

const isPasswordValid = computed(() => {
  if (!touched.password) return true
  return touched.password && password.value?.length >= 8
})

const isPasswordConfirmationValid = computed(() => {
  if (!touched.passwordConfirmation) return true
  return touched.passwordConfirmation && password.value === passwordConfirmation.value
})

const isFirstNameValid = computed(() => {
  if (!touched.first_name) return true
  return touched.first_name && userForm.value.first_name
})

const isLastNameValid = computed(() => {
  if (!touched.last_name) return true
  return touched.last_name && userForm.value.last_name
})

const isRestaurantNameValid = computed(() => {
  if (!touched.restaurant_name) return true
  return touched.restaurant_name && userForm.value.restaurant_name
})

const isRestaurationTypeIdValid = computed(() => {
  if (!touched.restauration_type_id) return true
  return touched.restauration_type_id && userForm.value.restauration_type_id
})



const isFirstStepValid = computed(() => {
  return (
    userForm.value.email &&
    isValidEmail(userForm.value.email) &&
    password.value?.length >= 8 &&
    password.value === passwordConfirmation.value &&
    userForm.value.first_name &&
    userForm.value.last_name
  )
})

const isSecondStepValid = computed(() => {
  return userForm.value.restaurant_name && userForm.value.restauration_type_id
})

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

function goToStepTwo() {
  if (isFirstStepValid.value) {
    activeStep.value++
  }
}

function openStepAccordingToErrors() {
  Object.keys(touched).forEach(key => touched[key] = true)
  if (Object.keys(errors.value).length > 0) {
    // Check if there are errors from step 1
    const step1Fields = ['email', 'password', 'first_name', 'last_name']
    const hasStep1Errors = step1Fields.some(field => errors.value[field])

    // Set activeStep based on which step has errors
    if (hasStep1Errors) {
      activeStep.value = 0
    }
    // If only step 2 errors, stay on step 2
  } else if (!isFirstStepValid.value) {
    activeStep.value = 0
  }
}
async function submitForm() {
  if (isFirstStepValid.value && isSecondStepValid.value) {
    await register(userForm.value, password.value, passwordConfirmation.value, toast)
    if (Object.keys(errors.value).length > 0) {
      openStepAccordingToErrors();
    }
  } else {
    // If validation fails, ensure we're on the first step
    openStepAccordingToErrors();
  }
}

const languagesList = ref<{ label: string; value: 'fr' | 'en' | 'de' | 'it' }[]>([
  { label: 'Français', value: 'fr' },
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Italiano', value: 'it' },
])

const selectedLanguage = ref(
  languagesList.value.find((language) => language.value === appStore.language) || languagesList.value[0]
)

function updateLanguage() {
  const newLanguage = selectedLanguage.value.value
  appStore.setLanguage(newLanguage)
}

onMounted(async () => {
  const { data } = await fetchData(useEnvStore().apiUrl + '/attributes/restauration-types')
  if (data) {
    restaurationTypes.value = data as Attribute[]
    userForm.value.restauration_type_id = restaurationTypes.value[0].id
  }

  const browserLanguage = navigator.language || navigator.languages[0]
  // Extract only the first two letters (e.g., 'en-US' -> 'en')
  const languageCode = browserLanguage.split('-')[0]
  console.log("browser language " + languageCode)
  // Check if your app supports the detected language
  const supportedLanguages = ['en', 'fr', 'de', 'it']
  if (supportedLanguages.includes(languageCode)) {
    appStore.setLanguage(languageCode as 'en' | 'fr' | 'de' | 'it')
    selectedLanguage.value = languagesList.value.find((language) => language.value === languageCode) || languagesList.value[0]
  }
})
</script>

<template>
  <main>
    <div class="flex justify-end mb-4">
      <Dropdown class="w-40" :options="languagesList" optionLabel="label" v-model="selectedLanguage"
        @change="updateLanguage">
        <template #value="slotProps">
          {{ slotProps.value.label }}
        </template>
        <template #option="slotProps">
          {{ slotProps.option.label }}
        </template>
      </Dropdown>
    </div>
    <!-- <form> -->
    <Stepper orientation="vertical" v-model:active-step="activeStep">
      <StepperPanel :header="t('auth.registration.personalData')">
        <template #content>
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <label for="email">{{ t('auth.user.form.label.mail') }} *</label>
              <div>
                <InputText type="email" id="email" class="w-full" v-model="userForm.email"
                  :placeholder="t('auth.user.form.placeholder.mail')" :invalid="hasError('email') || !isEmailValid"
                  @blur="touched.email = true" />
              </div>
              <InlineMessage severity="error" v-if="hasError('email') || !isEmailValid">
                {{ !isEmailValid ? t('auth.user.form.error.invalid_email') : errors.email[0] }}
              </InlineMessage>
            </div>
            <div class="flex flex-col gap-2">
              <label for="firstName">{{ t('auth.user.form.label.first_name') }} *</label>
              <div>
                <InputText type="text" id="firstName" v-model="userForm.first_name" class="w-full"
                  :placeholder="t('auth.user.form.placeholder.first_name')" :invalid="hasError('first_name') || !isFirstNameValid" 
                  @blur="touched.first_name = true"/>
              </div>
              <InlineMessage severity="error" v-if="hasError('first_name') || !isFirstNameValid">
                {{ !isFirstNameValid ? t('auth.user.form.error.invalid_first_name') : errors.first_name[0] }}
              </InlineMessage>
            </div>
            <div class="flex flex-col gap-2">
              <label for="lastName">{{ t('auth.user.form.label.last_name') }} *</label>
              <div>
                <InputText type="text" id="lastName" v-model="userForm.last_name" class="w-full"
                  :placeholder="t('auth.user.form.placeholder.last_name')" :invalid="hasError('last_name') || !isLastNameValid" 
                  @blur="touched.last_name = true"/>
              </div>
              <InlineMessage severity="error" v-if="hasError('last_name') || !isLastNameValid">
                {{ !isLastNameValid ? t('auth.user.form.error.invalid_last_name') : errors.last_name[0] }}
              </InlineMessage>
            </div>

            <div class="flex flex-col gap-2">
              <label for="password">{{ t('auth.user.form.label.password') }} *</label>
              <div>
                <Password id="password" v-model="password" :promptLabel="t('auth.user.form.placeholder.password')"
                  :weakLabel="t('auth.user.form.label.password_weak')"
                  :mediumLabel="t('auth.user.form.label.password_medium')"
                  :strongLabel="t('auth.user.form.label.password_strong')" :invalid="hasError('password') || !isPasswordValid"
                  :placeholder="t('auth.user.form.placeholder.password')" toggleMask class="w-full"
                  aria-describedby="password-help"
                  @blur="touched.password = true"/>
                <small id="password">{{ t('auth.user.form.label.password_help') }}</small>
              </div>
              <InlineMessage severity="error" v-if="hasError('password') || !isPasswordValid">
                {{ !isPasswordValid ? t('auth.user.form.error.invalid_password') : errors.password[0] }}
              </InlineMessage>
            </div>

            <div class="flex flex-col gap-2">
              <label for="passwordConfirmation">
                {{ t('auth.user.form.label.password_confirmation') }} *
              </label>
              <div>
                <Password id="passwordConfirmation" v-model="passwordConfirmation"
                  :promptLabel="t('auth.user.form.placeholder.password')"
                  :weakLabel="t('auth.user.form.label.password_weak')"
                  :mediumLabel="t('auth.user.form.label.password_medium')"
                  :strongLabel="t('auth.user.form.label.password_strong')" :invalid="hasError('password') || !isPasswordConfirmationValid"
                  :placeholder="t('auth.user.form.placeholder.password_confirmation')" toggleMask class="w-full"
                  aria-describedby="password-help" 
                  @blur="touched.passwordConfirmation = true"/>
                <small id="password">{{ t('auth.user.form.label.password_confirmation_help') }}</small>
              </div>
              <InlineMessage severity="error" v-if="hasError('password') || !isPasswordConfirmationValid">
                {{ !isPasswordConfirmationValid ? t('auth.user.form.error.invalid_password_confirmation') : errors.password[0] }}
              </InlineMessage>
            </div>
            <!-- <div class="flex flex-row justify-end gap-2">
              <Button :label="t('common.next')" icon="pi pi-arrow-right" iconPos="right" class="w-32"
                @click="nextCallback" />
            </div> -->
            <div class="flex flex-row justify-end gap-2">
              <Button :label="t('common.next')" icon="pi pi-arrow-right" iconPos="right" class="w-32"
                @click="goToStepTwo" :disabled="!isFirstStepValid" />
            </div>
          </div>
        </template>
      </StepperPanel>
      <StepperPanel :header="t('auth.registration.restaurantData')">
        <template #content="{ prevCallback }">
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <label for="restaurantName">{{ t('auth.user.form.label.restaurantName') }} *</label>
              <div>
                <InputText type="text" id="restaurantName" class="w-full" v-model="userForm.restaurant_name"
                  :placeholder="t('auth.user.form.placeholder.restaurantName')"
                  :invalid="hasError('restaurant_name') || !isRestaurantNameValid" 
                  @blur="touched.restaurant_name = true"/>
              </div>
              <InlineMessage severity="error" v-if="hasError('restaurant_name') || !isRestaurantNameValid">
                {{ !isRestaurantNameValid ? t('auth.user.form.error.invalid_restaurant_name') : errors.restaurant_name[0] }}
              </InlineMessage>
            </div>
            <div class="flex flex-col gap-2">
              <label for="restaurantName">
                {{ t('profile.profile.restaurantTab.restaurantionType.description') }} *
              </label>
              <div class="flex flex-col gap-2">
                <div class="flex flex-row items-center" v-for="attribute in restaurationTypes" :key="attribute.id">
                  <RadioButton v-model="userForm.restauration_type_id" :inputId="'radio-' + attribute.name"
                    :name="attribute.name" :value="attribute.id" />
                  <label :for="'radio-' + attribute.name" class="ml-2">
                    {{ t(`profile.profile.restaurantTab.restaurantionType.${attribute.name}`) }}
                  </label>
                </div>
              </div>
              <InlineMessage severity="error" v-if="hasError('restauration_type_id')">
                {{ errors.restauration_type_id[0] }}
              </InlineMessage>
            </div>
          </div>
          <div class="flex flex-row justify-end gap-2">
            <Button :label="t('common.back')" severity="secondary" outlined icon="pi pi-arrow-left" iconPos="left"
              class="w-32" @click="prevCallback" />
          </div>
          <Button :label="t('auth.registration.register')"
            :disabled="isFetching" :loading="isFetching" class="w-full mt-8"
            size="large" type="submit" @click="submitForm" />
        </template>
      </StepperPanel>
    </Stepper>
    <!-- </form> -->
    <Divider />
    <p>{{ t('auth.registration.alreadyHaveAccount') }} <InlineLink destination="/login">{{ t('auth.login.login') }}
      </InlineLink>
    </p>
  </main>
</template>
