<script setup lang="ts">
import ButtonRefresh from '@/assets/icons/Buttons/ButtonRefresh.vue'
import ChefHatFull from '@/assets/icons/2States/ChefHatFull.vue'
import ChefHatEmpty from '@/assets/icons/2States/ChefHatEmpty.vue'
import BaseCard from '@/components/BaseComponents/BaseCard.vue'
import Button from 'primevue/button'
import IngredientsList from '@/components/Menus/IngredientsList.vue'
import LoadingBar from '@/components/Skeletons/LoadingBar.vue'
import { type Dish } from '@/types/dish'
import { calculateDishPrice } from '@/utils/prices'
import type { MessageSchema } from '@/i18n'
import { useI18n } from 'vue-i18n'
import Skeleton from 'primevue/skeleton'
import { useDishesStore } from '@/stores/dishesStore'
import { computed, ref} from 'vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const props = withDefaults(
  defineProps<{
    dish?: Dish
  }>(),
  {
    dish: undefined,
  }
)


const dishesStore = ref(useDishesStore());
const isLoading = computed(() => dishesStore.value.isGenerating || props.dish?.loading);

</script>

<template>
  <div>
    <BaseCard v-if="isLoading && !props.dish?.ingredients.length" class="flex flex-col gap-3"
      :class="{ 'opacity-90': isLoading }">
      <div class="flex flex-row items-center gap-3">
        <h3 class="line-clamp-2 grow">{{ props.dish?.name }}
        </h3>
        <!-- <h3 class="line-clamp-2 grow">{{ isLoading ? `(${t('common.loading')})` : '' }} {{ props.dish?.name
          }}
        </h3> -->
        <!-- <Button rounded link :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
          :disabled="isLoading">
          <template #icon>
            <ButtonRefresh />
          </template>
  </Button> -->
      </div>
      <!-- <IngredientsList v-else :ingredients="props.dish.ingredients" gradient-from="from-primary-100"
        list-height="h-full" /> -->
      <Skeleton height=".75rem" class="bg-primary-300 dark:bg-primary-500 w-full" />
      <Skeleton height=".75rem" class="bg-primary-300 dark:bg-primary-500 w-full" />
      <Skeleton height=".75rem" class="bg-primary-300 dark:bg-primary-500 w-full" />
      <Skeleton height=".75rem" class="bg-primary-300 dark:bg-primary-500 w-full" />
      <div>
        <div class="flex flex-row items-center justify-between">
          <div class="flex flex-row w-24 h-6 gap-3 text-primary-950">
            <Skeleton height=".75rem" class="w-full bg-primary-300 dark:bg-primary-500 " />
          </div>
        </div>
      </div>
      <LoadingBar class="my-3" :durationInSeconds="14"
        v-if="dishesStore.generationParams.generation_type == 'quick' || dishesStore.dishes.length > 1" />

    </BaseCard>
    <BaseCard v-if="props.dish && props.dish.ingredients.length" class="flex flex-col gap-3"
      :pt="{ root: { class: '!p-4' } }" :pt-options="{ mergeProps: true }" :loading-icon="'pi pi-spin pi-spinner'">
      <div class="flex flex-row items-center gap-3">
        <h3 class="line-clamp-2 grow">{{ props.dish?.name }}
        </h3>
      </div>
      <div v-if="isLoading && !props.dish?.ingredients.length" class="flex flex-row">
        <p class="text-left">{{ t('menuDishView.loadingIngredientsLabel') }}</p>
      </div>
      <IngredientsList v-else-if="props.dish" :ingredients="props.dish.ingredients" gradient-from="from-primary-100"
        list-height="h-full" />

      <div v-if="!isLoading && props.dish">
        <div class="flex flex-row items-center justify-between">
          <div class="flex flex-row w-24 h-6 gap-3 text-primary-950">
            <ChefHatFull v-for="i in props.dish.difficulty" :key="i" />
            <ChefHatEmpty v-for="i in 4 - props.dish.difficulty" :key="i" />
          </div>
          <div class="px-3 py-1 text-sm text-white rounded-full bg-primary-500">
            <div>~ CHF {{ calculateDishPrice(props.dish) }} / pers.</div>
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>
