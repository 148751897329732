<script setup lang="ts">
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { useToast } from 'primevue/usetoast'
import { useEnvStore } from '@/stores/envStore'

const props = defineProps<{
  infoTotal: any
}>()
console.log('INFOS TOTAL' + props.infoTotal)

const toast = useToast()
const envStore = useEnvStore();

function copyText() {
  navigator.clipboard
    .writeText(JSON.stringify(props.infoTotal))
    .then(() => {
      toast.add({
        severity: 'success',
        summary: 'Texte copié avec succès!',
        detail: 'Le texte a été copié avec succès!',
        life: 3000,
      })
    })
    .catch(() => {
      toast.add({
        severity: 'error',
        summary: 'Copie impossible',
        detail: "Le texte n'a pas été copié",
        life: 3000,
      })
    })
}

function isDefined(value) {
  return typeof value !== 'undefined'
}
</script>

<template>
  <div v-if="envStore.environment !== 'production'" >
    <!-- <input class="button" type="button" @click="copyText" value="Copier le texte pour dev" /> -->
    <Accordion>
      <AccordionTab :header="`Informations de l'IA`">
        <div>
          <h1>Informations de l'IA</h1>
          <ul>
            <li>
              <b>Utilisation (tokens input / output): </b>
              {{ infoTotal.usage.prompt_tokens }} /
              {{ infoTotal.usage.completion_tokens }}
            </li>
            <li><b>Status: </b>{{ infoTotal.status }}</li>
            <li><b>ID de thread / chat: </b>{{ infoTotal.thread_id }}</li>
            <li><b>Modèle d'IA: </b>{{ infoTotal.ai_model }}</li>
            <li><b>Température:</b> {{ infoTotal.temperature }}</li>
            <li><b>Durée de réponse de l'IA: </b>{{ infoTotal.duration }} secondes</li>
          </ul>
          <h1>Instructions à l'IA :</h1>
          <p>{{ infoTotal.instructions }}</p>
        </div>
      </AccordionTab>
      <AccordionTab :header="`Informations du formulaire`">
        <div>
          <div v-if="infoTotal.max_price">
            <h1>Formulaire</h1>
            <ul>
              <li><b>Date: </b>{{ new Date(infoTotal.date).toLocaleString() }}</li>
              <!-- <li><b>Profil: </b>{{ infoTotal.profile }}</li> -->
              <li><b>Titre personnalisé: </b>{{ infoTotal.custom_title || 'Non spécifié' }}</li>
              <li><b>Sucré ou salé: </b>{{ infoTotal.sweet_salt || 'Non spécifié' }}</li>
              <li><b>Type de plat (génération rapide): </b>{{ infoTotal.dish_type || 'Non spécifié' }}</li>
              <li><b>Produit principal: </b>{{ infoTotal.main_product || 'Non spécifié' }}</li>
              <!-- <li><b>Prix: </b> De {{ infoTotal.price_from }} CHF à {{ infoTotal.price_to }} CHF maximum</li> -->
              <li><b>Prix maximum: </b> {{ infoTotal.max_price }} CHF </li>
              <li v-if="infoTotal.hot_cold"><b>Type de plat: </b>{{ infoTotal.hot_cold.join(', ') || 'Non spécifié' }}
              </li>
              <li v-if="isDefined(infoTotal.dish_inspirations)">
                <b>Inspirations de plat: </b>{{ infoTotal.dish_inspirations.join(', ') || 'Non spécifié' }}
              </li>
              <li v-if="isDefined(infoTotal.dish_diet)">
                <b>Régime alimentaire:</b>
                {{ infoTotal.dish_diet.join(', ') || 'Non spécifié' }}
              </li>
              <li v-if="isDefined(infoTotal.dish_season)"><b>Saison du plat: </b>{{ infoTotal.dish_season.join(',') || 'Non spécifié' }}
              </li>
              <li v-if="isDefined(infoTotal.dish_product_preferences)">
                <b>Préférences de produit: </b>{{ infoTotal.dish_product_preferences.join(', ') || 'Non spécifié' }}
              </li>
              <li v-if="isDefined(infoTotal.supp_ingredients)">
                <b>Ingrédients supplémentaires: </b>{{ infoTotal.supp_ingredients.join(', ') || 'Aucun' }}
              </li>
              <li><b>Prompt supplémentaire: </b>{{ infoTotal.supp_prompt }}</li>
              <li><b>Type de génération: </b>{{ infoTotal.generation_type }}</li>
            </ul>
            <br /><br />
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  </div>
</template>
