import { createI18n } from 'vue-i18n'
import frCH from '@/i18n/locales/frCH.json'
import en from '@/i18n/locales/en.json'
import de from '@/i18n/locales/de.json'
import it from '@/i18n/locales/it.json'

export type MessageSchema = typeof frCH

// Get the locale from localStorage or default to the environment variable
const savedLocale = localStorage.getItem('language') || import.meta.env.VITE_DEFAULT_LOCALE

const i18n = createI18n<[MessageSchema], 'fr' | 'en' | 'de' | 'it'>({
  locale: savedLocale, // Initialize locale from localStorage or fallback to default
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE, // Set your fallback locale if needed
  legacy: false,
  globalInjection: true,
  messages: {
    'fr': frCH,
    en: en,
    de: de,
    it: it,
  },
})

// Update the locale and store it in localStorage
export function setLocale(locale: 'fr' | 'en' | 'de' | 'it') {
  // Cast to WritableComputedRef<string> to handle the .value property correctly
  (i18n.global.locale as any).value = locale // Update the reactive value
  localStorage.setItem('language', locale) // Save the new locale to localStorage
}

export default i18n
