<script setup lang="ts">
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import type { ModelRef } from 'vue';
import { useUserStore } from '@/stores/useUserStore';
import { useFetch } from '@/composables/useFetch';
import AvatarPrompt from '@/assets/icons/Avatars/AvatarPrompt.vue';
import Avatar from '@/assets/icons/Avatars/Avatar.vue';

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const visible: ModelRef<boolean | undefined> = defineModel('visible', { required: true })
const props = defineProps<{
  creditsLeft: number
}>()

const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void
}>()

const userStore = useUserStore()

function handleConfirm() {
  userStore.confirmCreditWarning()
  visible.value = false
}
</script>

<template>
  <Dialog v-model:visible="visible" modal :header="t('credits.warning.title')" :style="{ width: '25rem' }">
    <Avatar class="w-32 my-4 mx-auto" />
    <p>{{ t('credits.warning.message', { credits: userStore.remainingCredits }) }}</p>
    <template #footer>
      <Button :label="t('common.ok')" @click="handleConfirm" />
    </template>
  </Dialog>
</template>