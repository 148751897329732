<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { computed, ref } from 'vue'
import MultiSelect from 'primevue/multiselect'
import countries from '@/assets/countries.json'
import { watch } from 'vue'
import { useAuth } from '@/composables/useAuth'
import { useAppStore } from '@/stores/appStore'


const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const appStore = useAppStore();
const props = defineProps<{
  attributes: Array<{ id: number, name: string }>,
  restaurantAttributes: Array<{ id: number, name: string }>
}>();

const emit = defineEmits(['updateCountries']);

const countryIdMap = new Map(props.attributes.map(attr => [attr.name, attr.id]))

const allCountries = computed(() => {
  return countries.map(country => ({
    ...country,
    id: countryIdMap.get(country.alpha2)  // Lier les ID backend
  }))
})

const selectedCountries = ref(props.restaurantAttributes.map(attr => attr.id))

watch(selectedCountries, (newValues, oldValues) => {
  // Determine additions and removals by comparing old and new values
  const newSet = new Set(newValues);
  const oldSet = new Set(oldValues);

  const added = newValues.filter(id => !oldSet.has(id));
  const removed = oldValues.filter(id => !newSet.has(id));

  added.forEach(id => {
    console.log("Added country ID: ", id);
    emit('updateCountries', id);
  });

  removed.forEach(id => {
    console.log("Removed country ID: ", id);
    emit('updateCountries', id);
  });
}, { deep: true });


</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.inspirations') }}</template>
    <template #content>
      <MultiSelect v-model="selectedCountries" multiple filter display="chip"
        class="w-full" :optionLabel="appStore.language" optionValue="id" :options="allCountries"
        :virtualScrollerOptions="{ itemSize: 50 }" :pt="{ root: { class: 'h-9 items-center' } }"
        :pt-options="{ mergeProps: true }" :placeholder="t('creationFilters.inspirationsDropdownLabel')" />
    </template>
  </CreationFilter>
</template>
