<script setup lang="ts">
import { useDishesStore } from '@/stores/dishesStore'
import { onMounted, ref } from 'vue'
import type { Dish } from '@/types/dish'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import { useToast } from 'primevue/usetoast'
import DishImage from '@/components/Menus/DishImage.vue'
import LoadingBar from '@/components/Skeletons/LoadingBar.vue'
import DishImageSkeleton from '../../components/Skeletons/DishImageSkeleton.vue'
import { useRoute, useRouter } from 'vue-router'
import Button from 'primevue/button'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const route = useRoute()
const router = useRouter()
const toast = useToast()
const envStore = useEnvStore()
const dishesStore = useDishesStore()
const dish = ref<Dish | null>(dishesStore.selectedDish)
dish.value = dishesStore.selectedDish
const isError = ref(false)
const { fetchData } = useFetch()

// mounted
onMounted(async () => {
  // dishesStore.selectedDish = null
  dish.value = null
  const { data } = await fetchData(`${envStore.apiUrl}/dishes/${route.params['id']}`)
  if (data) {
    dishesStore.selectedDish = data
    dish.value = data
  } else {
    router.push('/menu/dishes/' + route.params['id'])
  }
})

async function changeSelectedImage(newlySelectedImage: 'user' | 'gastro' | 'bistro') {
  if (!dish.value) return
  
  const success = await dishesStore.changeSelectedImage(dish.value.id, newlySelectedImage)
  if (success && dish.value.selected_image !== 'none') {
    router.back()
  }
}

function regenerateImages() {
  if (!dishesStore.selectedDish) return;
  dishesStore.generateImages(dishesStore.selectedDish.id, toast)
}
</script>

<template>
  <main>
    <h2 class="mb-2">{{ dish ? dish.name : t('common.loading') }}</h2>
    <div class="hover:cursor-pointer my-4" @click="regenerateImages" v-if="!dishesStore.generatingImage">
      <p><i class="pi pi-replay"></i> {{ t('menuDishView.regenerateImage') }}</p>
    </div>
    <LoadingBar class="my-5" :durationInSeconds="15" v-if="dishesStore.generatingImage" />
    <div v-if="dishesStore.generatingImage">
      <h3>{{ t('menuDishView.image.gastronomicImage') }}</h3>
      <div class="flex justify-center">
        <DishImageSkeleton />
      </div>
      <h3 class="mt-10 mb-1">{{ t('menuDishView.image.bistronomicImage') }}</h3>
      <div class="flex justify-center">
        <DishImageSkeleton />
      </div>
    </div>
    <div v-if="!dishesStore.generatingImage">
      <div v-if="dish?.image_url_gastro">
        <h3>{{ t('menuDishView.image.gastronomicImage') }}</h3>
        <div class="flex flex-row gap-3 justify-center mt-2">

          <DishImage :dishId="dish?.id" type="gastro" :alt="dish?.name" :selected="dish.selected_image === 'gastro'"
            @handleSelect="changeSelectedImage('gastro')" />
          <!-- <div class="flex flex-col gap-2">
            <Button icon="pi pi-replay" rounded aria-label="Regenerate" />
            <Button icon="pi pi-trash" severity="danger" rounded aria-label="Delete" />
          </div> -->
        </div>
      </div>
      <div v-if="dish?.image_url_bistro">
        <h3 class="mt-10 mb-1">{{ t('menuDishView.image.bistronomicImage') }}</h3>
        <div class="flex flex-row gap-3 justify-center mt-2">
          <DishImage :dishId="dish?.id" type="bistro" :alt="dish?.name" :selected="dish.selected_image === 'bistro'"
            @handleSelect="changeSelectedImage('bistro')" />
          <!-- <div class="flex flex-col gap-2">
            <Button icon="pi pi-replay" rounded aria-label="Regenerate" />
            <Button icon="pi pi-trash" severity="danger" rounded aria-label="Delete" />
          </div> -->
        </div>
      </div>
      <div v-if="dish?.image_url_user">
        <h3 class="mt-10 mb-1">{{ t('menuDishView.image.customImage') }}</h3>
        <div class="flex flex-row gap-3 justify-center mt-2">
          <DishImage :dishId="dish?.id" type="user" :alt="dish?.name" :selected="dish.selected_image === 'user'"
            @handleSelect="changeSelectedImage('user')" />
          <!-- <div class="flex flex-col gap-2">
            <Button icon="pi pi-replay" rounded aria-label="Regenerate" />
            <Button icon="pi pi-trash" severity="danger" rounded aria-label="Delete" />
          </div> -->
        </div>
      </div>
      <p v-else-if="isError">{{ t('menuImage.imageLoadError') }}</p>
    </div>
    <div class="flex justify-end gap-2 mt-2">
      <!-- TODO : put back buttons once ready-->
      <!-- <Button rounded link :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
        @click="console.log('reload')">
        <template #icon>
          <ButtonRefresh />
        </template>
</Button>
<Button rounded link :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
  @click="console.log('share')">
  <template #icon>
          <ButtonShare />
        </template>
</Button> -->
    </div>
  </main>
</template>
