<script setup lang="ts">
import { useAuth } from '@/composables/useAuth'
import type { MessageSchema } from '@/i18n'
import { usePlanningStore } from '@/stores/planningStore'
import Button from 'primevue/button'
import { onMounted, ref, watch, type ModelRef, type Ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'primevue/usetoast'
import { useDishesStore } from '@/stores/dishesStore'
import Dialog from 'primevue/dialog'
import MultiSelect from 'primevue/multiselect'
import type { DishTag } from '@/types/api'
import Chip from 'primevue/chip'
import { useUserStore } from '@/stores/useUserStore'

const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const openAddTagToDishDialog: ModelRef<boolean | undefined> = defineModel('visible', { required: true })
const dishesStore = useDishesStore();
const props = defineProps<{
  selectedTags: DishTag[] | []
}>()


const toast = useToast()
const user = ref(useUserStore().user)
const restaurantId: string = user.value?.restaurants[0].id || ''
const newTagSelection: Ref<DishTag[] | []> = ref([])

const emit = defineEmits<{
  (e: 'closeSidebar'): void
  (e: 'updateSelection', newTagSelection: DishTag[]): DishTag[] | []
}>()

const isTagsLoaded = ref(false)
watch(openAddTagToDishDialog, async (newValue) => {
  if (newValue) {
    isTagsLoaded.value = false
    if (dishesStore.tags.length === 0) {
      await dishesStore.fetchTags(restaurantId, toast)
    }

    // Remove pivot from tag
    newTagSelection.value = props.selectedTags.map(tag => {
      const { pivot, ...rest } = tag;
      return rest;
    });
    isTagsLoaded.value = true
  }
})

// Add this watch after other watch statements
watch(
  () => props.selectedTags,
  (newTags) => {
    if (newTags) {
      // Remove pivot from tags
      newTagSelection.value = newTags.map(tag => {
        const { pivot, ...rest } = tag;
        return rest;
      });
    }
  },
  { immediate: true }
);

async function updateTagsOnDish() {
  emit('updateSelection', newTagSelection.value)
  openAddTagToDishDialog.value = false;
}

onMounted(async () => {
  if (!user.value) return
  if (dishesStore.tags.length === 0) {
    dishesStore.fetchTags(restaurantId, toast)
  }
  isTagsLoaded.value = true
});

// Add this computed property to format tags with parent-child relationship
const formattedTags = computed(() => {
  return newTagSelection.value.map(tag => {
    if (tag.parent_dish_tag_id && dishesStore.tags.length > 0) {
      // get parent dish tag from the dishesStore.tags
      const parentTag = dishesStore.tags.find(t => t.id === tag.parent_dish_tag_id);
      if (parentTag) {
        return {
          ...tag,
          displayName: `${parentTag.name} ${tag.name === 'uncategorized' ? '' : ' | ' + tag.name}`
        };
      }
    }
    return {
      ...tag,
      displayName: te(`dishTag.tagNames.${tag.name}`) ? t(`dishTag.tagNames.${tag.name}`) : tag.name
    };
  });
});

async function removeTag(tag) {
  newTagSelection.value = newTagSelection.value.filter((t) => t.id !== tag.id)
}
</script>
<template>
  <Dialog v-model:visible="openAddTagToDishDialog" modal :header="t('dishTag.add.label')" :style="{ width: '25rem' }">
    <div v-if="dishesStore.tags.length === 0">
      <p>{{ t('dishTag.noTagsAvailable') }}</p>
    </div>
    <div v-else class="flex flex-col gap-4 mb-6">
      <div v-if="newTagSelection.length === 0">
        <p>{{ t('dishTag.noTagSelected') }}</p>
      </div>
      <div v-else class="flex flex-row flex-wrap gap-2">
        <Chip :label="tag.displayName" v-for="tag in formattedTags" :key="tag.id" removable>
          <template #default>
            <div class="leading-4 my-1.5 mx-0 text-xs" style="font-size: 12px">{{ tag.displayName }}</div>
          </template>
          <template #removeicon>
            <i class="pi pi-times-circle" @click="removeTag(tag)" style="cursor: pointer;" />
          </template>
        </Chip>
      </div>
      <MultiSelect 
        v-model="newTagSelection" 
        :options="dishesStore.tags"
        :optionLabel="(option) => te(`dishTag.tagNames.${option.name}`) ? t(`dishTag.tagNames.${option.name}`) : option.name"
        :placeholder="t('dishTag.selectTags')" 
        :maxSelectedLabels="0"
        :selectedItemsLabel="t('dishTag.itemsSelected', { count: newTagSelection.length })" 
        class="w-full md:w-20rem" 
        optionGroupLabel="name"
        optionGroupChildren="child_tags" 
      />
    </div>
    <div class="flex justify-between gap-2">
      <Button type="button" :label="t('common.cancel')" outlined @click="openAddTagToDishDialog = false"
        :disabled="false"></Button>
      <div class="flex justify-end gap-2">
        <Button type="button" :label="t('common.save')" @click="updateTagsOnDish" :disabled="false"></Button>
      </div>
    </div>
  </Dialog>
</template>
