<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4 0C7.756 0 4 3.756 4 8.4C4 14.7 12.4 24 12.4 24C12.4 24 20.8 14.7 20.8 8.4C20.8 3.756 17.044 0 12.4 0ZM6.4 8.4C6.4 5.088 9.088 2.4 12.4 2.4C15.712 2.4 18.4 5.088 18.4 8.4C18.4 11.856 14.944 17.028 12.4 20.256C9.904 17.052 6.4 11.82 6.4 8.4Z"
      fill="currentColor"
    />
    <path
      d="M12.4 11.4C14.0569 11.4 15.4 10.0569 15.4 8.4C15.4 6.74315 14.0569 5.4 12.4 5.4C10.7431 5.4 9.4 6.74315 9.4 8.4C9.4 10.0569 10.7431 11.4 12.4 11.4Z"
      fill="currentColor"
    />
  </svg>
</template>
