import { ref } from 'vue'
import { AxiosError } from 'axios'
import axios from '@/utils/axios'
// import { useAuth } from './useAuth'
import { useUserStore } from '@/stores/useUserStore'

const isFetching = ref(false)
export const previousCreditsLeft = ref<number | null>(null)

export function useFetch() {

  async function fetchData(
    url: string,
    method: 'GET' | 'DELETE' | 'POST' | 'PUT' | 'PATCH' = 'GET',
    body?: Object | FormData,
    timeoutParam: number = 60000
  ) {
    // const user = useAuth().user;
    let data: any = null
    let error: any = null

    isFetching.value = true

    try {
      if ((method === 'POST' || method === 'PUT') && !body) {
        throw new Error('Missing body')
      }

      const isFormData = body instanceof FormData

      const options = {
        method,
        headers: {
          Accept: 'application/json',
          ...(isFormData
            ? { 'Content-Type': 'multipart/form-data' }
            : { 'Content-Type': 'application/json' }),
        },
        data: isFormData ? body : JSON.stringify(body),
        timeout: timeoutParam,
      }

      const response = await axios(url, options)

      data = response.data

      // Update user's restaurant info if applicable
      if (
        data.restaurant &&
        data.restaurant.total_credits !== undefined &&
        data.restaurant.credits_used !== undefined
      ) {
        const userStore = useUserStore()
        userStore.updateCredits(
          data.restaurant.total_credits,
          data.restaurant.credits_used
        )
      }
    } catch (err) {
      const axiosError = err as AxiosError
      // console.log("AXIOS ERROR: ", axiosError)
      if (axiosError.response) {
        const responseData = axiosError.response.data as { error_code?: string }
        // console.log("RESPONSE DATA: ", responseData)
        if (responseData.error_code === 'INSUFFICIENT_CREDITS') {
          // console.log("INSUFFICIENT CREDITS: ", responseData)
          // Handle insufficient credits - you could notify the user or trigger any specific UI action
          error = {
            code: 'INSUFFICIENT_CREDITS',
            message: 'You have insufficient credits. Please top up your credits to continue.',
            path: url,
            timestamp: new Date(),
            status: 403,
          }
          // Example action: Emit an event or update state to notify the user
          // e.g., EventBus.emit('insufficient-credits')
        } else {
          error = {
            code: axiosError.response ? 'ERR_BAD_RESPONSE' : 'ERR_ABORTED',
            details: axiosError.response ? axiosError.response.data : axiosError,
            message: axiosError.response ? 'Bad response from server' : 'Failed to fetch',
            path: url,
            timestamp: new Date(),
            status: axiosError.response ? axiosError.response.status : 500,
          }
        }
      }
    } finally {
      isFetching.value = false
    }
    return { data, error }
  }

  return { fetchData, isFetching }
}
