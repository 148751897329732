<script setup lang="ts">
import { computed, markRaw, onMounted, ref, type Ref } from 'vue'
// import { useI18n } from 'vue-i18n'
// import type { MessageSchema } from '@/i18n/index.js'
import Button from 'primevue/button'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import { watch } from 'vue'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import { useToast } from 'primevue/usetoast'
import { useDishesStore } from '@/stores/dishesStore'
import MyRecipesMenuItemInDialog from './MyRecipesMenuItemInDialog.vue'
import { useUserStore } from '@/stores/useUserStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const emits = defineEmits(['select-category', 'select-tag'])

const openDishTagModal: Ref<boolean> = ref(false)
const newTagName: Ref<string> = ref('');
// const myDishTags: Ref<DishTag[] | []> = ref([])
const { fetchData } = useFetch()
const envStore = useEnvStore()
const toast = useToast()
const user = ref(useUserStore().user)
const dishesStore = useDishesStore();
const fetchingTags = computed(() => dishesStore.fetchingTags);
const restaurantId = user.value?.restaurants[0]?.id || null

const links = [
  {
    name: t('pageTitle.myFavorites'),
    icon: null,
    category: 'favorites',
  },
]

// Add parentId to manage current parent tag
const parentId = ref<number | null>(null)

// Compute the current tag name based on parentId
const tagName = computed(() => {
  if (!parentId.value) return ''
  const currentTag = dishesStore.tags.find(tag => tag.id === parentId.value)
  return currentTag ? currentTag.name : ''
})

// Update myCategories to show child tags if parentId is set
const myCategories = computed(() => {
  if (parentId.value) {
    const parentTag = dishesStore.tags.find(tag => tag.id === parentId.value)
    return (parentTag?.child_tags || []).map(tag => ({
      name: tag.name,
      icon: null,
      tag: tag.id,
    }))
  } else {
    return dishesStore.tags.map(tag => ({
      name: tag.name,
      icon: null,
      tag: tag.id,
    }))
  }
})

// Add function to navigate between tags without redirection
const navigateToTag = (tagId: number) => {
  const tag = dishesStore.tags.find(tag => tag.id === tagId)
  if (tag?.child_tags && tag.child_tags.length > 0) {
    parentId.value = tagId // Navigate into the tag
  } else {
    emits('select-tag', tagId) // Emit event to parent
  }
}

function goBack() {
  if (parentId.value) {
    parentId.value = null; // Go back to root tags
  } else {
    emits('select-category', null); // Emit to parent to go back
  }
}

async function addDishTag() {

  if (newTagName.value.trim() !== '') {
    const newTag = {
      name: newTagName.value.trim(),
      parent_dish_tag_id: parentId.value,
    };

    try {
      const payload = {
        tags: [newTag],
      }
      const response = await fetchData(`${envStore.apiUrl}/dishes/tags/${restaurantId}`, 'POST', payload);

      if (response.data) {
        dishesStore.tags = [...dishesStore.tags, ...response.data.tags];
        // toast.add({
        //   severity: 'success',
        //   summary: t('common.success'),
        //   detail: t('dishTag.add.success'),
        //   life: 3000,
        // });
      } else if (response.error) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: response.error,
          life: 3000,
        });
      }
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('dishTag.add.error'),
        life: 3000,
      });
    }
  }

  openDishTagModal.value = false;
}

onMounted(async () => {
  await dishesStore.fetchTags(restaurantId, toast);
})

watch(openDishTagModal, (newValue) => {
  if (!newValue) {
    newTagName.value = '';
  }
});
</script>

<template>
  <main>
    <div class="flex items-center justify-start grow my-4">
      <Button rounded link icon="pi pi-chevron-left" class="text-xl"
        :pt="{ root: { class: ' !w-8 !h-8' } }" :pt-options="{ mergeProps: true }" @click="goBack" />
    </div>
    <MyRecipesMenuItemInDialog v-for="(link, index) in links" :key="link.name" :name="link.name"
      :icon="link?.icon ?? undefined" :index="index" @click="$emit('select-category', link.category)" />
    <div class="my-7">
      <h1 class="text-2xl">{{ t('homeView.myRecipes.myCategories') }}</h1>
      <div class="mt-4">
        <p v-if="fetchingTags" class="mt-4 italic">{{ t('common.loading') }}</p>
        <p v-else-if="!fetchingTags && myCategories.length === 0" class="mt-4 italic">{{ t('dishTag.noTags') }}</p>
        <MyRecipesMenuItemInDialog v-else v-for="(link, index) in myCategories" :key="link.name" :name="link.name"
          :icon="link?.icon ?? undefined" :index="index" @click="navigateToTag(link.tag)" />
      </div>
      <!-- <Button @click="null" :label="t('common.seeMore')" /> -->
      <!-- <div class="mt-4">
        <p @click="openDishTagModal = true" class="mb-10 inline-button">
          {{ t('dishTag.add.label') }}
        </p>
      </div> -->
    </div>
  </main>
</template>
