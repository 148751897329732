<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23 20C23 20 15.6385 20 1 20L23 20Z" stroke="currentColor" stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M11.7973 8.31739H10.6379C6.00023 8.31739 2.52174 11.987 2 17H22C21.4783 12.0853 17.5944 8.31739 12.9567 8.31739H11.7973ZM11.7973 8.31739L11.3045 7.35904V6.37611V5.09829H10.174H9.65229V5H13.8262V5.09829H13.3045H12.174V6.37611V7.35904L11.7973 8.31739Z"
      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M16 4.32708C16.6005 4.32708 17.126 4.10188 17.5013 3.65147C17.8767 3.20107 18.1019 2.60054 18.1019 2H19.0777C19.0777 2.60054 19.3029 3.20107 19.6783 3.65147C20.0536 4.10188 20.5791 4.32708 21.1796 4.32708V5.37802C20.5791 5.37802 20.0536 5.60322 19.6783 6.05362C19.3029 6.50402 19.0777 7.10456 19.0777 7.70509H18.1019C18.1019 7.10456 17.8767 6.50402 17.5013 6.05362C17.126 5.60322 16.6005 5.37802 16 5.37802V4.32708Z"
      fill="currentColor" />
    <path
      d="M21 1.49998C21.3529 1.49998 21.6176 1.32352 21.8823 1.05881C22.147 0.794109 22.2353 0.441172 22.2353 0H22.7647C22.7647 0.352937 22.8529 0.794109 23.1176 1.05881C23.3823 1.32352 23.647 1.49998 24 1.49998V2.20586C23.647 2.20586 23.3823 2.38233 23.1176 2.64703C22.8529 2.91173 22.7647 3.26467 22.7647 3.70584H22.2353C22.2353 3.35291 22.147 2.91173 21.8823 2.64703C21.6176 2.38233 21.3529 2.20586 21 2.20586V1.49998Z"
      fill="currentColor" />
  </svg>
</template>