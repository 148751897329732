<script setup lang="ts">
import { useProfileRestaurant } from '@/composables/useProfileRestaurant.js'
import type { MessageSchema } from '@/i18n/index.js'
import type { AttributesList, Restaurant } from '@/types/restaurants.js'
import Checkbox from 'primevue/checkbox'
import { useToast } from 'primevue/usetoast'
import { computed, onMounted, ref, watchEffect, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  attributes: AttributesList
  restaurant: Restaurant
}>()
const emit = defineEmits<{
  (e: 'updateRestaurant', restaurant: Restaurant): void
}>()

const toast = useToast()
const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const selectAllStates = ref({})
const categoryToInclude = ['cookingEquipments', 'kitchenMachines']

const filteredAttributes = computed(() =>
  Object.fromEntries(
    Object.entries(props.attributes).filter(([key]) => categoryToInclude.includes(key))
  )
)

const checkedAttributes: Ref<AttributesList> = ref(props.restaurant.attributes)

watchEffect(() => console.log(checkedAttributes.value))

const { updateRestaurantAttributes } = useProfileRestaurant(toast, props.restaurant.id)

async function updateAttribute(attributeId: number) {
  const updateResponse = await updateRestaurantAttributes({ attributeIds: [attributeId] })
  if (updateResponse !== null) emit('updateRestaurant', updateResponse)
}

async function updateAttributes(attributeIds: number[], toggleAll?: boolean) {
  const updateResponse = await updateRestaurantAttributes({ attributeIds, toggleAll })
  if (updateResponse !== null) emit('updateRestaurant', updateResponse)
}

function toggleSelectAll(categoryName) {
  const allAttributesInCategory = filteredAttributes.value[categoryName]
  console.log('selectAllStates.value[categoryName] ', selectAllStates.value[categoryName])
  // TODO : check reactivity : the value is the old one when the function is called. That's we need to reverse the condition (!isSelectAllStates.value[categoryName])
  if (!selectAllStates.value[categoryName]) {
    // If select all is checked, add all attributes to checkedAttributes
    checkedAttributes.value[categoryName] = allAttributesInCategory
    console.log('checkedAttributes ', checkedAttributes.value[categoryName])
  } else {
    // If select all is unchecked, clear the category from checkedAttributes
    checkedAttributes.value[categoryName] = []
    console.log('checkedAttributes ', checkedAttributes.value[categoryName])
  }

  const attributesIds = allAttributesInCategory.map((attribute) => attribute.id)
  updateAttributes(attributesIds, true)
  // Update each attribute in the backend
  // allAttributesInCategory.forEach(attribute => {
  //   // TODO : send all the id in one request instead of one by one
  //   updateAttribute(attribute.id);
  // });
}

onMounted(() => {
  categoryToInclude.forEach((category) => {
    selectAllStates.value[category] = filteredAttributes.value[category].every((attr) =>
      checkedAttributes.value[category]?.map((ca) => ca.id).includes(attr.id)
    )
  })
})
</script>

<template>
  <div v-for="(attributeArray, categoryName) in filteredAttributes" :key="categoryName">
    <h3 class="mb-2">{{ t('profile.profile.toolingView.' + categoryName) }}</h3>
    <div class="flex flex-col gap-4 mb-8">
      <div class="flex items-center gap-2 italic">
        <Checkbox
          v-model="selectAllStates[categoryName]"
          :inputId="`selectAll-${categoryName}`"
          @click="toggleSelectAll(categoryName)"
          :binary="true"
        />
        <label :for="`selectAll-${categoryName}`" class="ml-2 hover:cursor-pointer">{{
          t('common.selectAll')
        }}</label>
      </div>
      <div
        v-for="attribute in attributeArray"
        :key="attribute.id"
        class="flex flex-row items-center gap-2 ml-3"
      >
        <Checkbox
          :name="categoryName.toString()"
          :value="attribute"
          :inputId="attribute.id.toString()"
          v-model="checkedAttributes[categoryName]"
          @click="updateAttribute(attribute.id)"
        />
        <label :for="attribute.id.toString()">
          {{ t('profile.profile.toolingView.' + attribute.name) }}
        </label>
      </div>
    </div>
  </div>
</template>
