import { useEnvStore } from '@/stores/envStore'
import { useFetch } from './useFetch'
import i18n from '@/i18n'
import type { Dish } from '@/types/dish'
import { usePlanningStore } from '@/stores/planningStore'
import { useDishesStore } from '@/stores/dishesStore'
import jsPDF from 'jspdf'
import OpenSansBold from '@/assets/fonts/open-sans/OpenSans-Bold.ttf'
import OpenSansRegular from '@/assets/fonts/open-sans/OpenSans-Regular.ttf'
import OpenSansLight from '@/assets/fonts/open-sans/OpenSans-Light.ttf'
import AvatarLogo from '@/assets/images/avatar-rounded.webp'
import type { User } from '@/types/api'

const { fetchData } = useFetch()
const { t } = i18n.global

export const usePlanning = () => {
  function stripProtocol(url: string | null | undefined): string {
    if (!url) return ''
    return url.replace(/^(https?:\/\/)/, '')
  }

  function formatWebsiteUrl(url: string): string {
    if (!url) return ''
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url
    }
    return `https://${url}`
  }

  async function planDish(dishId: number): Promise<boolean> {
    console.log("planning dish ") // TODO
    // const url = useEnvStore().apiUrl + '/planning/plan'
    // const { error } = await fetchData(url, 'DELETE')
    // if (error) {
    //   console.log(error)
    //   return false
    // }
    return true
  }

  async function duplicatePlannedDishAndUpdatePlan(dish: Dish, menuCourseId: number, plannedMenuId: number) {

    const url = useEnvStore().apiUrl + '/planning/planned-menus/' + plannedMenuId + '/menu-courses/' + menuCourseId;

    const { data, error } = await fetchData(url, 'POST', { 'dish_id': dish.id })
    if (error || !data.dish) {
      console.log(error)
      return null
    }

    const planningStore = usePlanningStore();
    const dishesStore = useDishesStore();
    // const duplicationResponse: Dish | null = await duplicateDish(dish.id);
    const newDish = data.dish;
    // console.log(data.dish)
    if (dishesStore.selectedDish && dishesStore.selectedDish?.id === dish.id) {
      dishesStore.selectedDish = { ...newDish! };
    };

    if (planningStore.selectedPlannedMenu && planningStore.selectedPlannedMenu?.id === plannedMenuId) {
      planningStore.selectedPlannedMenu.menu_courses.find(menuCourse => menuCourse.id === menuCourseId)!.dish = { ...newDish! };
    }
    planningStore.shouldRefetch = true;
    dishesStore.shouldRefetch = true;

    planningStore.plannedMenus.find(plannedMenu => plannedMenu.id === plannedMenuId)!.menu_courses.find(menuCourse => menuCourse.id === menuCourseId)!.dish = { ...newDish! };
    return newDish;
  }

  function formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear()).slice(-2)
    return `${day}.${month}.${year}`
  }

  const convertWebpToPng = (imgSrc: string): Promise<{ dataUrl: string; aspectRatio: number }> => {
    return new Promise((resolve) => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.drawImage(img, 0, 0)
        }
        resolve({
          dataUrl: canvas.toDataURL('image/png'),
          aspectRatio: img.width / img.height
        })
      }
      img.src = imgSrc
    })
  }

  async function generatePDF(user: User) {

    const planningStore = usePlanningStore()

    // Convert both logos
    const { dataUrl: transparentPng, aspectRatio: avatarAspectRatio } = await convertWebpToPng(AvatarLogo);
    const restaurantLogo = user?.restaurants[0].logo;
    let restaurantLogoData;
    if (restaurantLogo) {
      restaurantLogoData = await convertWebpToPng(restaurantLogo);
    }

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4"
    });

    // Add fonts
    doc.addFont(OpenSansBold, 'OpenSans-Bold', 'bold');
    doc.addFont(OpenSansRegular, 'OpenSans-Regular', 'regular');
    doc.addFont(OpenSansLight, 'OpenSans-Light', 'light');

    // Page margins
    const margin = 15;
    const gutter = 3;
    const pageWidth = 210;
    const pageHeight = 297;

    // Title section - use OpenSans Bold
    doc.setFont('OpenSans-Bold', 'bold');
    doc.setFontSize(16);

    // Restaurant Info (Add restaurant name, logo, email, phone)
    const restaurant = user.restaurants[0];
    const restaurantName = restaurant.name;
    const website = stripProtocol(restaurant.website_url || '');
    const phone = restaurant.phone_number || '';

    // Calculate squares layout (8 sections)
    const columnGap = 5; // 5mm gap between columns
    const squareWidth = (pageWidth - (2 * margin) - columnGap) / 2; // 2 columns with gap
    const squareHeight = (pageHeight - 2 * (margin + gutter)) / 4;
    const padding = 5;
    const lineHeight = 4; // Height for each line

    // Helper function to get position for each section (0-7)
    const getPosition = (index: number) => {
      const row = Math.floor(index / 2);
      const col = index % 2;
      return {
        x: margin + (col * (squareWidth + columnGap)),
        y: margin + row * squareHeight
      };
    };

    // Draw days (skipping position 1 which is for restaurant info)
    const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    weekDays.forEach((day, index) => {
      // Adjust position to skip restaurant info slot
      const position = index >= 1 ? getPosition(index + 1) : getPosition(index);
      const { x, y } = position;

      // Draw day content
      doc.setFont('OpenSans-Bold', 'bold');
      doc.setFontSize(12);
      const dayX = x + (squareWidth / 2);
      doc.text(t(`common.days.${day}`), dayX, y + 5, { align: 'center' });

      // Add underline
      doc.setDrawColor('#dedede'); // primary-100
      doc.setLineWidth(0.2);
      doc.line(x + 3, y + 7, x + squareWidth - 3, y + 7);

      // Get menus for this day
      const currentDate = new Date(planningStore.weekRange.start);
      currentDate.setDate(currentDate.getDate() + index);
      const dateString = currentDate.toISOString().split('T')[0];
      const menus = planningStore.getMenusByDate(dateString);

      // Calculate menu layout - no padding
      const menuWidth = menus.length === 1 ? squareWidth : squareWidth / 2;

      // Draw menus
      menus.forEach((menu, menuIndex) => {
        console.log("MENU ", menu)
        const menuX = x + (menuIndex * menuWidth);
        const menuY = y + 7;

        // Add vertical separator line between menus if there are 2 menus
        if (menus.length > 1 && menuIndex === 0) {
          // Calculate vertical line position
          const lineX = menuX + menuWidth;
          const lineStartY = menuY + ((squareHeight - 7) / 2) - 7.5; // Center the 15mm line
          const lineEndY = lineStartY + 12; // 15mm height

          doc.setDrawColor(200, 200, 200); // Light grey color
          doc.setLineWidth(0.2); // Thin line
          doc.line(lineX, lineStartY, lineX, lineEndY);
        }

        // Draw menu container for dev purpose
        // doc.setDrawColor(200, 200, 200);
        // doc.rect(menuX, menuY, menuWidth, squareHeight - 10);

        // Center align and truncate menu title if needed
        doc.setFontSize(10);
        doc.setFont('OpenSans-Regular', 'regular');
        const maxTitleWidth = menuWidth - 4; // Leave small margin
        let menuTitle = menu.name;
        while (doc.getTextWidth(menuTitle) > maxTitleWidth && menuTitle.length > 3) {
          menuTitle = menuTitle.slice(0, -4) + '...';
        }
        const menuTitleX = menuX + (menuWidth / 2);
        doc.text(menuTitle, menuTitleX, menuY + 5, { align: 'center' });

        // Calculate available space for dishes and separators
        const contentHeight = squareHeight - 20; // Total height minus title area
        const dishAreaHeight = contentHeight / 3; // Equal height for each dish area

        // Always render 3 dish slots
        for (let courseIndex = 0; courseIndex < 3; courseIndex++) {
          const areaStartY = menuY + 7 + (courseIndex * dishAreaHeight);

          doc.setFont('OpenSans-Light', 'light');
          doc.setFontSize(8);

          // Find the course with matching order (courseIndex + 1)
          const course = menu.menu_courses?.find(course => course.order === courseIndex + 1);
          const courseText = course ? `${course.name}` : '-';

          // Split text into lines that fit within the menu width, limit to 2 lines
          const maxWidth = menuWidth - 4; // Slightly reduced width for margins
          let splitText = doc.splitTextToSize(courseText, maxWidth);
          if (splitText.length > 2) {
            splitText = splitText.slice(0, 2);
            // Add ellipsis to last line if text was truncated
            splitText[1] = splitText[1].substring(0, splitText[1].length - 3) + '...';
          }

          // Calculate vertical centering within dish area
          const totalTextHeight = splitText.length * lineHeight;
          const startY = areaStartY + (dishAreaHeight - totalTextHeight) / 2;

          // Center and draw each line
          splitText.forEach((line: string, i: number) => {
            const lineWidth = doc.getTextWidth(line);
            const lineX = menuX + (menuWidth - lineWidth) / 2;
            doc.text(line, lineX, startY + (i * lineHeight));
          });

          // Draw centered "***" separator if not the last dish
          if (courseIndex < 2) {
            doc.setFontSize(8);
            const separatorText = '***';
            const separatorWidth = doc.getTextWidth(separatorText);
            const separatorX = menuX + (menuWidth - separatorWidth) / 2;
            doc.text(
              separatorText,
              separatorX,
              areaStartY + dishAreaHeight - 2 // Place separator near bottom of dish area
            );
          }
        }
      });
    });

    // Draw restaurant info in position 1 (top right)
    const restaurantPos = getPosition(1);

    if (restaurantLogoData) {
      const maxLogoHeight = 25;
      const maxLogoWidth = 60;
      let logoHeight = maxLogoHeight;
      let logoWidth = logoHeight * restaurantLogoData.aspectRatio;

      if (logoWidth > maxLogoWidth) {
        logoWidth = maxLogoWidth;
        logoHeight = logoWidth / restaurantLogoData.aspectRatio;
      }

      const logoX = restaurantPos.x + (squareWidth / 2) - (logoWidth / 2);
      doc.addImage(restaurantLogoData.dataUrl, 'PNG', logoX, restaurantPos.y, logoWidth, logoHeight);
    }

    // Restaurant text info
    doc.setFont('OpenSans-Bold', 'bold');
    doc.setFontSize(16);
    const logoOffset = restaurantLogoData ? 32 : 5;
    doc.text(restaurantName, restaurantPos.x + (squareWidth / 2), restaurantPos.y + logoOffset + 5, { align: 'center' });
    doc.setFontSize(12);
    doc.text(t('planning.menuOfTheWeek'), restaurantPos.x + (squareWidth / 2), restaurantPos.y + logoOffset + 12, { align: 'center' });
    doc.setFont('OpenSans-Regular', 'regular');
    doc.text(
      `${formatDate(planningStore.weekRange.start)} - ${formatDate(planningStore.weekRange.end)}`,
      restaurantPos.x + (squareWidth / 2),
      restaurantPos.y + squareHeight - 15,
      { align: 'center' }
    );

    // Footer section
    const footerY = pageHeight - (margin / 2);
    const footerHeight = 15; // Height of footer area
    const footerCenterY = footerY - (footerHeight / 2); // Vertical center of footer

    // Left side content (avatar + powered by text)
    const logoHeight = 12; // mm
    const maxLogoWidth = 24; // mm
    let logoWidth = logoHeight * avatarAspectRatio;
    if (logoWidth > maxLogoWidth) {
      logoWidth = maxLogoWidth;
    }

    // Calculate text widths for left side
    doc.setFont('OpenSans-Light', 'light');
    doc.setFontSize(7);
    const poweredByText = 'Powered by ';
    const poweredByWidth = doc.getTextWidth(poweredByText);

    doc.setFont('OpenSans-Bold', 'bold');
    const gastronomiaText = 'GastronomIA';
    const gastronomiaWidth = doc.getTextWidth(gastronomiaText);

    // Draw avatar logo on the left
    const logoY = footerCenterY - (logoHeight / 2); // Center vertically
    doc.addImage(transparentPng, 'PNG', margin, logoY, logoWidth, logoHeight);

    // Draw "Powered by GastronomIA" text vertically centered
    const textY = footerCenterY + 2; // Small adjustment for visual alignment
    doc.setTextColor(23, 52, 71); // primary-950 RGB values
    doc.setFont('OpenSans-Light', 'light');
    doc.text(poweredByText, margin + logoWidth + 2, textY);
    doc.setFont('OpenSans-Bold', 'bold');
    doc.text(gastronomiaText, margin + logoWidth + 2 + poweredByWidth, textY);

    // Right side (website + phone)
    doc.setFont('OpenSans-Light', 'light');
    doc.setTextColor(0, 0, 0);
    const contactText = website ? website : phone;
    const contactWidth = doc.getTextWidth(contactText);
    doc.text(contactText, pageWidth - margin - contactWidth, textY);

    // Save the PDF
    doc.save(`menu-plan-${formatDate(planningStore.weekRange.start)}-${formatDate(planningStore.weekRange.end)}.pdf`);
  }

  return {
    planDish,
    duplicatePlannedDishAndUpdatePlan,
    generatePDF,
    stripProtocol,
    formatWebsiteUrl
  }
}
