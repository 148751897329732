<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import RecipeStep from '@/components/Menus/RecipeStep.vue'
import {
  useRecipeStore,
  useDishesStore,
  useDishCreationEditionFormStore,
} from '@/stores/dishesStore'
import GenerationInfos from '@/components/Admin/GenerationInfos.vue'
import ButtonEdit from '@/assets/icons/Buttons/ButtonEdit.vue'
import Button from 'primevue/button'
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, onBeforeUnmount, ref, type Ref } from 'vue'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { Dish, Ingredient, Recipe } from '@/types/dish.js'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import BaseLoader from '@/components/BaseComponents/BaseLoader.vue'
import { adjustUnitsForQuantity, calculateIngredientPrice } from '@/utils/prices'
// import html2pdf from 'html2pdf.js'
import AvatarLogo from '@/assets/images/avatar-rounded.webp';


// Add these imports
import DishImage from '@/components/Menus/DishImage.vue'
import NutritionalValuesCard from '@/components/Menus/MenuDishViewComponents/NutritionalValuesCard.vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const router = useRouter()
const route = useRoute()
const dishId = route.params.id
const mustReload = route.query.mustReload === 'true' ? true : false

const { fetchData } = useFetch()

const recipeStore = useRecipeStore()
const recipe: Ref<Recipe | null> = ref(null)
const infoTotal = recipeStore.infoTotal

const dishesStore = useDishesStore()
const dish: Ref<Dish | null> = ref(null)

const originalTitle = document.title

function formatDocumentTitle(): string {
  const date = new Date().toISOString().split('T')[0]
  const formattedDishName = (dish.value?.name || 'recipe').toLowerCase().replace(/\s+/g, '-')
  return `${date}-gstr-${formattedDishName}`
}

function handleBeforePrint() {
  document.title = formatDocumentTitle()
}

function handleAfterPrint() {
  document.title = originalTitle
}

function editRecipe() {
  console.log("edit recipe")
  console.log("dish.value ", dish.value)
  if (dish.value && recipe.value) {

    // const adjustedIngredients = listIngredients.value
    const adjustedIngredients = dish.value.ingredients

    useDishCreationEditionFormStore().creationEditionDish = {
      dish_data: {
        id: dish.value.id,
        name: dish.value.name,
        chill_time_min: dish.value.chill_time_min,
        cook_time_min: dish.value.cook_time_min,
        prep_time_min: dish.value.prep_time_min,
        difficulty: dish.value.difficulty,
        servings: dish.value.servings,
        ingredients: adjustedIngredients,
        steps: recipe.value.steps,
      },
    }
    router.push({ name: 'dish-creation-edition-form', query: { edit: 'true' } })
  }
}

onMounted(async () => {
  window.addEventListener('beforeprint', handleBeforePrint)
  window.addEventListener('afterprint', handleAfterPrint)
  
  if (!dishesStore.selectedDish || mustReload) {
    const { data, error } = await fetchData(useEnvStore().apiUrl + `/dishes/${dishId}`)
    if (error) {
      console.error(error)
    } else {
      console.log(data)
      dish.value = data as Dish
      console.log("DISH VALUE FROM MOUNTED IN MENU RECIPE STEP", dish.value)
      dish.value.ingredients = dish.value.ingredients.map((ingredient) => {
        return {
          ...ingredient,
          quantity: ingredient.quantity === 0 ? undefined : ingredient.quantity,
        }
      })
      dishesStore.selectedDish = dish.value
    }
  } else {
    dishesStore.selectedDish.ingredients = dishesStore.selectedDish.ingredients.map(
      (ingredient) => {
        return {
          ...ingredient,
          quantity: ingredient.quantity === 0 ? undefined : ingredient.quantity,
        }
      }
    )
    dish.value = dishesStore.selectedDish
  }

  if (!recipeStore.recipe || mustReload) {
    const { data, error } = await fetchData(useEnvStore().apiUrl + `/dishes/${dishId}/recipe`)
    if (error) {
      console.error(error)
    } else {
      console.log(data)
      recipe.value = data as Recipe
      recipeStore.recipe = recipe.value
    }
  } else {
    recipe.value = recipeStore.recipe
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('beforeprint', handleBeforePrint)
  window.removeEventListener('afterprint', handleAfterPrint)
})

const listIngredients = computed(() => {
  if (!dish.value) return [];

  const servings = dish.value.servings

  let ingredients = dish.value.ingredients.map((ingredient: Ingredient) => {
    let converted = { unit: ingredient.unit || '', quantity: ingredient.quantity || 0 }
    converted = adjustUnitsForQuantity(converted.quantity, converted.unit, servings);
    return {
      ...ingredient,
      quantity: converted.quantity,
      unit: converted.unit,
      price: calculateIngredientPrice(ingredient.price, servings)
    };
  });

  ingredients.sort((a, b) => a.id - b.id);

  return ingredients;
})

// Add this computed property
const hasImage = computed(() => {
  return dish.value?.image_url_gastro || dish.value?.image_url_bistro || dish.value?.image_url_user
})

// Update the time formatting function
function formatTime(minutes: number): string {
  if (minutes === null || minutes === undefined) return '';
  const hrs = Math.floor(minutes / 60);
  const mins = minutes % 60;
  const parts: string[] = [];
  if (hrs > 0) {
    parts.push(t('common.hoursShort', { count: hrs }));
    if (mins > 0) {
      parts.push(mins.toString());
    }
  } else if (mins > 0) {
    parts.push(t('common.minutesShort', { count: mins }));
  }
  return parts.join('');
}

function generatePDF() {
  window.print()
}
</script>

<template>
  <table class="w-full">
    <thead>
      <tr>
        <td>
          <div class="header-space">&nbsp;</div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <main>
            <div v-if="recipe && dish" class="flex flex-col gap-6">
              <div class="flex flex-row justify-end align-middle gap-2 print:hidden"> <!-- Add no-print class to buttons -->
                <Button rounded link :pt="{ root: { class: '!p-1.5 !w-10 !h-10' } }" :pt-options="{ mergeProps: true }"
                  @click="generatePDF">
                  <!-- PrimeVue default icon -->
                  <i class="pi pi-file-pdf text-xl"></i>
                </Button>

                <Button rounded link :pt="{ root: { class: '!p-1.5 !w-10 !h-10' } }" :pt-options="{ mergeProps: true }"
                  @click="editRecipe">
                  <!-- Custom icon with adjusted size -->
                  <template #icon>
                    <ButtonEdit class="text-xl" /> <!-- Ensure ButtonEdit scales properly -->
                  </template>
                </Button>
              </div>
              <div class="flex justify-between my-2">
                <h1>{{ dish.name }}</h1>
              </div>
              <div v-if="hasImage" class="print:flex justify-center items-start m-1 text-center hidden">
                <DishImage :dishId="dish.id" :type="dish.selected_image" :alt="dish.name" class="m-auto w-80" size="full" />
              </div>
              <div class="flex flex-row justify-between">
                <div class="my-1">
                  <h2>{{ t('profileFavorites.creationForm.informations') }}</h2>
                  <p class="grid grid-cols-4">
                    <span class="col-span-3"> {{ t('profileFavorites.creationForm.prepTime') }} : </span>
                    <span> {{ formatTime(dish.prep_time_min) }} </span>
                  </p>
                  <p class="grid grid-cols-4">
                    <span class="col-span-3"> {{ t('profileFavorites.creationForm.chillTime') }} : </span>
                    <span>{{ formatTime(dish.chill_time_min) }} </span>
                  </p>
                  <p class="grid grid-cols-4">
                    <span class="col-span-3">{{ t('profileFavorites.creationForm.cookTime') }} : </span>
                    <span>{{ formatTime(dish.cook_time_min) }}</span>
                  </p>
                  <p class="grid grid-cols-4">
                    <span class="col-span-3">{{ t('profileFavorites.creationForm.numberOfPeople') }} : </span>
                    <span>{{ dish.servings }}</span>
                  </p>
                </div>
                <div class="hidden print:block my-1">
                  <h2 class="mb-1">{{ t('dish.nutritional.nutritionalValues') }}</h2>
                  <NutritionalValuesCard :dish="dish" small />
                </div>
              </div>
              <div class="my-2" :class="[hasImage ? 'print:break-before-page' : '']">
                <h2>{{ t('profileFavorites.creationForm.ingredients') }}</h2>
                <DataTable :value=" listIngredients" size="small">
                  <Column field="quantity" :header="t('profileFavorites.creationForm.quantityShort')">
                    <template #body="slotProps">
                      {{ slotProps.data.quantity > 0 ? slotProps.data.quantity : '' }}
                    </template>
                  </Column>
                  <Column field="unit" :header="t('profileFavorites.creationForm.unit')" />
                  <Column field="name" :header="t('profileFavorites.creationForm.name')" />
                  <Column field="price" :header="t('profileFavorites.creationForm.price')" class="print:hidden" />
                </DataTable>
              </div>
              <div v-if="recipe" class="my-4">
                <h2>{{ t('profileFavorites.creationForm.instructions') }}</h2>
                <div class="flex flex-col gap-6">
                  <RecipeStep v-for="step in recipe.steps" :key="step.name" :step-name="step.name"
                    :instructions="step.instructions" />
                </div>
              </div>

              <GenerationInfos :info-total="infoTotal" v-if="infoTotal" />
            </div>
            <div class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" v-else>
              <BaseLoader />
            </div>
          </main>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>
          <div class="footer-space">&nbsp;</div>
        </td>
      </tr>
    </tfoot>
  </table>

  <footer class="hidden print:block fixed-footer">
    <div class="flex items-center justify-between max-w-screen-xl mx-auto py-1">
      <div class="flex items-center gap-2">
        <img :src="AvatarLogo" alt="GastronomIA Logo" class="h-8 w-8 object-contain" />
        <p class="text-xs">
          <span class="text-gray-600">Powered by</span>
          <span class="font-bold text-primary-700 ml-1">GastronomIA</span>
        </p>
      </div>
    </div>
  </footer>
</template>

<style scoped>
@page {
  margin: 3mm;
}

.footer-space {
  height: 50px; 
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
}

@media print {
  table {
    width: 100%;
  }
  
  .fixed-footer {
    position: fixed;
    bottom: 0;
  }

  .print\:break-before-page {
    break-before: page;
  }

  .print\:hidden {
    display: none;
  }
}
</style>