<script setup lang="ts">
import ChevronLeftIcon from '@/assets/icons/Planning/ChevronLeftIcon.vue'
import ChevronRightIcon from '@/assets/icons/Planning/ChevronRightIcon.vue'
import type { MessageSchema } from '@/i18n'
import { usePlanningStore } from '@/stores/planningStore'
import { type Ingredient } from '@/types/dish'
import WeekMenuView from '@/views/Planning/WeekMenuView.vue'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import { computed, onUpdated, ref, watch, type ModelRef } from 'vue'
import { useI18n } from 'vue-i18n'
import DishType from './DishType.vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { useDishesStore } from '@/stores/dishesStore'
import { useUserStore } from '@/stores/useUserStore'

const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const isLoading = ref(false);
// const { categories } = useCategories();
const showSideBar: ModelRef<boolean | undefined> = defineModel('visible', { required: true })
const sideBarIngredient: ModelRef<Ingredient | undefined> = defineModel('ingredient')
// const ingredientCategory = { name: t('orders.ingredientsCategories.other'), value: 'other' }
// const dishesStore = useDishesStore();
// const ingredientUnits = ref(dishesStore.units);
// const { isFetching, fetchData } = useFetch()

const router = useRouter()
const toast = useToast()
const user = ref(useUserStore().user)
const planningStore = usePlanningStore()

const edition = ref(false)
const dishName = computed(() => {
  return planningStore.dishToPlan?.clipboardDish.name ?? ''
})

const emit = defineEmits<{
  (e: 'closeSidebar'): void
}>()

onUpdated(() => {
  edition.value = sideBarIngredient.value?.id === -1 ? false : true
})

const currentDate = ref(new Date())
const currentWeekNumber = ref(getWeekNumber(currentDate.value))
const weekRange = ref(getWeekRange(currentDate.value))

function getWeekNumber(date: Date): number {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1)
  const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
}

function getWeekRange(date: Date): { start: Date; end: Date } {
  const start = new Date(date)
  const dayOfWeek = start.getDay()
  const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek // If Sunday, go to Monday of the same week
  start.setDate(start.getDate() + diffToMonday)

  const end = new Date(start)
  end.setDate(start.getDate() + 6) // Get the following Sunday

  return { start, end }
}

function goToPreviousWeek() {
  currentDate.value.setDate(currentDate.value.getDate() - 7)
  currentWeekNumber.value = getWeekNumber(currentDate.value)
  weekRange.value = getWeekRange(currentDate.value)
}

function goToNextWeek() {
  currentDate.value.setDate(currentDate.value.getDate() + 7)
  currentWeekNumber.value = getWeekNumber(currentDate.value)
  weekRange.value = getWeekRange(currentDate.value)
}

// Formatting dates
function formatDate(date: Date): string {
  const day = String(date.getDate()).padStart(2, '0') // Ensures 2-digit day
  const month = String(date.getMonth() + 1).padStart(2, '0') // Ensures 2-digit month
  const year = String(date.getFullYear()).slice(-2) // Gets last 2 digits of the year
  return `${day}.${month}.${year}`
}

function unselectPlannedMenu() {
  planningStore.selectedPlannedMenu = null
}

watch(
  weekRange,
  async (newRange) => {
    const restaurantId = user.value?.restaurants[0]?.id || null
    await planningStore.fetchMenusIfNeeded(
      newRange.start.toISOString().split('T')[0],
      newRange.end.toISOString().split('T')[0],
      restaurantId
    )
  },
  { deep: true, immediate: true }
)

watch(showSideBar, (newValue) => {
  if (!newValue) {
    planningStore.dishToPlan = null
  }
})

async function planDish() {
  // console.log('plan dish : ', planningStore.dishToPlan)
  isLoading.value = true;
  const dishesStore = useDishesStore();

  if (!planningStore.dishToPlan) {
    console.error("dishToPlan is null or undefined");
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('planning.plannedMenu.planDish.error.noDishToPlan'),
      life: 1500,
    });
    isLoading.value = false;
    return;
  }

  const { clipboardDish, selectedPlannedMenuIds, dishType } = planningStore.dishToPlan;

  if (!clipboardDish || selectedPlannedMenuIds.length === 0 || !dishType) {
    console.error("Missing required fields for planning a dish");
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('planning.plannedMenu.planDish.error.missingFields'),
      life: 1500,
    });
    isLoading.value = false;
    return;
  }

  try {
    const data = await planningStore.planDish(clipboardDish, selectedPlannedMenuIds, dishType);



    if (router.currentRoute.value.path.startsWith('/menu/dishes/')) {
      if (data.new_dish) {
        await router.replace({ path: `/menu/dishes/${data.new_dish.id}` });
        dishesStore.selectedDish = data.new_dish;
      } else {
        dishesStore.selectedDish = data.dish;
      }
    }

    planningStore.dishToPlan = null;

    // toast.add({
    //   severity: 'success',
    //   summary: t('common.success'),
    //   detail: t('planning.plannedMenu.planDish.success'),
    //   life: 1500,
    // });

    // if (router.currentRoute.value.path.startsWith('/menu/dishes/')) {
    //   router.push('/planning');
    // }
  } catch (error) {
    console.error('Failed to plan dish', error);
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('planning.plannedMenu.planDish.error.failed'),
      life: 1500,
    });
  } finally {
    isLoading.value = false;
  }
  // await planningStore.planDish(router, toast)
  if (!planningStore.dishToPlan) showSideBar.value = false
}
</script>
<template>
  <Sidebar v-model:visible="showSideBar" :header="t('menuDishView.addDishtoWeekMenu', { dishName: dishName })"
    position="bottom" style="height: 90%" :block-scroll="true" :pt="{
      content: { class: 'px-0 pb-6 mt-6 h-full w-full grow overflow-y-auto' },
    }" class="w-full md:w-3/4 lg:w-1/2">
    <template #header>
      <h2 class="w-full mb-0">{{ t('menuDishView.planDishDialogTitle') }}</h2>
    </template>

    <div class="mx-6">
      <p class="py-2 font-bold">{{ dishName }}</p>
      <DishType />
    </div>

    <div v-if="!planningStore.selectedPlannedMenu && planningStore.dishToPlan?.dishType" class="mb-6">
      <div class="flex justify-center items-center my-4">

        <!-- Previous week button -->
        <button @click="!planningStore.isLoading ? goToPreviousWeek() : null" class="rounded-l-lg">
          <ChevronLeftIcon class="w-12 h-12 text-primary-500" />
        </button>

        <!-- Display the week number and date range -->
        <div class="px-4 py-2 text-center">
          <div>{{ t('planning.weekInfo', { weekNumber: currentWeekNumber }) }}</div>
          <div class="text-sm">
            {{ formatDate(weekRange.start) }} - {{ formatDate(weekRange.end) }}
          </div>
        </div>
        <button @click="!planningStore.isLoading ? goToNextWeek() : null" class="rounded-r-lg">
          <ChevronRightIcon class="w-12 h-12 text-primary-500" />
        </button>
      </div>

      <WeekMenuView :weekRange></WeekMenuView>
    </div>
    <!-- <div v-else>
        <div class="z-10 flex items-center justify-start grow">
          <Button rounded link icon="pi pi-chevron-left" class="text-xl" :pt="{ root: { class: ' !w-8 !h-8' } }"
          :pt-options="{ mergeProps: true }" @click="!planningStore.isLoading ? unselectPlannedMenu() : null" />
      </div>
      <PlannedMenuView />
    </div> -->
    <div v-if="planningStore.dishToPlan"
      class="fixed  md:w-3/4 lg:w-1/2 m-auto inset-x-0 bottom-0 z-50 px-4 py-3 bg-white custom-shadow">
      <Button :label="t('menuDishView.planDishButton')" @click="!planningStore.isLoading ? planDish() : null"
        class="w-full" :disabled="planningStore.dishToPlan?.selectedPlannedMenuIds.length === 0" />
    </div>
  </Sidebar>
</template>
