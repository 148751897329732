<script setup lang="ts">
import LoadingView from '../LoadingView.vue'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { DishHistoryResponse } from '@/types/api.js'
import Button from 'primevue/button'
import { computed, nextTick, onMounted, ref, watch, type Ref } from 'vue'
import AutoComplete from 'primevue/autocomplete'
import Checkbox from 'primevue/checkbox'
import type { MessageSchema } from '@/i18n'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import type { Dish } from '@/types/dish.js'
import type { HistoryState } from '@/types/history.js'
import { useToast } from 'primevue/usetoast'
import { useProfileDishLists } from '@/composables/useProfileDishLists.js'
import HistoryCard from '@/components/Profile/HistoryCard.vue'
import { usePlanningStore } from '@/stores/planningStore'
import TitleSuggestionButton from '@/components/Menus/TitleSuggestionButton.vue'
import AddDishTitleToPlanningDialog from '@/components/Planning/AddDishTitleToPlanningDialog.vue'
import { useConfirm } from 'primevue/useconfirm'
import { useDishesStore } from '@/stores/dishesStore'
import LoadingBar from '@/components/Skeletons/LoadingBar.vue'
import ConfirmDialog from 'primevue/confirmdialog'
import { useUserStore } from '@/stores/useUserStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const router = useRouter()
const toast = useToast()
const user = ref(useUserStore().user)
const dishesStore = useDishesStore();


const { fetchData } = useFetch()

const apiUrl = useEnvStore().apiUrl

const searchQuery = ref('')
const isSearching = ref(false)

const createdUsing: Ref<string[]> = ref(['ocr', 'ia'])

const isFetchingList = ref(false)
const isGenerating = ref(false);

const nextPageUrl = ref(`${apiUrl}/dishes`)
const loadedDishes = ref(0)
const totalDishes = ref(0)
const planningStore = usePlanningStore()
const selectedDishTitle: Ref<Dish | null> = ref(null);
const showAddToPlanningDialog: Ref<boolean> = ref(false)
const confirm = useConfirm()


const { dishTitles, handleDuplication, handleDeletion, handleFavoriteChange } =
  useProfileDishLists(toast)

const groupedDishesByDate = computed(() => {
  const groups: { [key: string]: Dish[] } = {}
  dishTitles.value.forEach((dish) => {
    const date = new Date(dish.created_at).toLocaleDateString('fr-CH', { year: 'numeric', month: '2-digit', day: '2-digit' })
    if (!groups[date]) {
      groups[date] = []
    }
    groups[date].push(dish)
  })
  return Object.entries(groups)
})

function handleCardClick(event, dish: Dish, index, sourceArray) {

  if (planningStore.choosingDishToPlan) {
    selectedDishTitle.value = dish;
    planDishTitle();
    return;
  } else {
    confirmGenerateNewDish(event, dish, index, sourceArray)
  }
  // } else {
  //   saveState()
  //   router.push(`/menu/dishes/${id}`)
  // }
}
function planDishTitle() {
  if (!selectedDishTitle.value) return
  if (planningStore.choosingDishToPlan) {
    emit('addDishTitleToPlan', selectedDishTitle.value);
    return
  }

  planningStore.dishTitleToPlan = {
    clipboardDishTitle: selectedDishTitle.value.name,
    selectedPlannedMenuIds: [],
    dishType: 'main'
  };

  showAddToPlanningDialog.value = true

}

// When the users wants to add a dish from the planning.
const emit = defineEmits<{
  (e: 'addDishTitleToPlan', dishTitle: Dish): void
}>()

async function getDishes() {
  // This var is needed because the isFetching var from composable
  // Makes a conflict between the getDishes and the searchDishes methods
  isFetchingList.value = true

  const { data, error } = await fetchData(nextPageUrl.value, 'GET')
  if (error) {
    console.log('error', error)
  } else {
    const response = data as DishHistoryResponse
    console.log('response', response)
    dishTitles.value = [...dishTitles.value, ...response.data]
    loadedDishes.value = response.to
    totalDishes.value = response.total
    nextPageUrl.value = response.next_page_url ?? ''
  }

  isFetchingList.value = false
}

// This method is only triggered when we type in the serach bar or when the checkboxes are clicked.
// The pagination is the handled by getDishes
async function filterDishes() {
  // isSearching is needed to display search icon when the method is triggered with the watcher
  isSearching.value = true

  // const searchUrl = `${apiUrl}/dishes/search?name=${encodeURIComponent(searchQuery.value)}`
  console.log("FETCHING ")
  const searchUrl = `${apiUrl}/dishes/titles/search?${new URLSearchParams({
    name: searchQuery.value,
    created_using: createdUsing.value.join(','),
    // is_planned: 'true',
  })}`

  const { data, error } = await fetchData(searchUrl, 'GET')

  if (error) {
    console.log('error', error)
  } else {
    const response = data as DishHistoryResponse
    dishTitles.value = response.data
    loadedDishes.value = response.to
    totalDishes.value = response.total
    nextPageUrl.value = response.next_page_url ?? ''
  }
  isSearching.value = false
}

const scrollPosition = ref(0)

function saveState() {
  const stateToSave: HistoryState = {
    dishes: dishTitles.value,
    loadedDishes: loadedDishes.value,
    totalDishes: totalDishes.value,
    nextPageUrl: nextPageUrl.value,
    scrollPosition: window.scrollY,
    searchQuery: searchQuery.value,
    createdUsing: createdUsing.value,
  }
  sessionStorage.setItem('titleHistoryState', JSON.stringify(stateToSave))
}

function restoreState() {
  dishTitles.value = []
  const savedState = sessionStorage.getItem('titleHistoryState')
  if (savedState) {
    const state: HistoryState = JSON.parse(savedState)
    dishTitles.value = state.dishes
    loadedDishes.value = state.loadedDishes
    totalDishes.value = state.totalDishes
    nextPageUrl.value = state.nextPageUrl
    scrollPosition.value = state.scrollPosition
    searchQuery.value = state.searchQuery
    createdUsing.value = state.createdUsing || [] // Provide default empty array if not found
    console.log(scrollPosition.value)
    nextTick(() => {
      window.scrollTo(0, scrollPosition.value)
    })
    sessionStorage.removeItem('titleHistoryState')
  } else {
    filterDishes()
  }
}

watch(searchQuery, () => {
  if (searchQuery.value === '') {
    filterDishes()
  }
})

const confirmGenerateNewDish = (event, dish: Dish, index, sourceArray) => {
  const dishTitle = dish.name;
  selectedDishTitle.value = dish;
  confirm.require({
    group: 'templating',
    header: dishTitle,
    // message: t('menuDishView.generateDishFromTitle.popupLabelGenerateOrPlan', { dishTitle }),
    // message: '',
    icon: 'pi pi-question-circle',
    acceptLabel: t('menuDishView.generateDishFromTitle.planDishTitle'),
    // rejectLabel: t('menuDishView.generateDishFromTitle.confirm'),
    accept: () => {
      // handleCardClick(dish)
      selectedDishTitle.value = dish;
      planDishTitle();
      toast.add({
        severity: 'info',
        summary: t('common.loading'),
        detail: t('menuDishView.loadingMenuLabel'),
        life: 3000,
      })
    },
    reject: () => {
      // selectedDishTitle.value = dish;
      // generateDishFromTitle();
      // console.log('reject')
    },
  });

  // confirm.require({
  //   target: event.currentTarget as HTMLElement,
  //   message: t('menuDishView.generateDishFromTitle.popupLabelGenerateOrPlan', { dishTitle }),
  //   icon: 'pi pi-question-circle',
  //   acceptLabel: t('menuDishView.generateDishFromTitle.planDishTitle'),
  //   rejectLabel: t('menuDishView.generateDishFromTitle.confirm'),
  //   accept: () => {
  //     // generateDishFromTitle(dishTitle, index, sourceArray)
  //     handleCardClick(dish)
  //     toast.add({
  //       severity: 'info',
  //       summary: t('common.loading'),
  //       detail: t('menuDishView.loadingMenuLabel'),
  //       life: 3000,
  //     })
  //   },
  //   reject: () => {
  //     selectedDishTitle.value = dish;
  //     generateDishFromTitle();
  //   },
  // })
}

function handleGenerateDishFromTitle(rejectCallback) {
  // selectedDishTitle.value = dish;
  generateDishFromTitle();
  rejectCallback();
}

async function generateDishFromTitle() {
  isGenerating.value = true;
  if (!user.value) return;
  const restaurantId = user.value.restaurants[0].id
  if (!selectedDishTitle.value) {
    console.error('Title is required to generate a dish');
    return;
  }
  // generatingRecipe.value = true;
  // if (!user.value.restaurants[0]) router.go(-1);
  const theDishToGenerate = { ...selectedDishTitle.value } as Dish;
  // dish.value = null
  const dish = await dishesStore.generateDishFromTitle(theDishToGenerate.name, restaurantId, theDishToGenerate.id)
  // generatingRecipe.value = false;
  if (!dish) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('creationFilters.generationError'),
      life: 3000,
    })
    isGenerating.value = false;
    return;
  }
  // toast.add({
  //   severity: 'success',
  //   summary: t('common.success'),
  //   detail: t('creationFilters.generationSuccess'),
  //   life: 3000,
  // })
  isGenerating.value = false;
  router.push({ path: `/menu/dishes/${dish.id}` })
}

onMounted(() => {
  restoreState()
})
</script>

<template>
  <main>
    <div v-if="isGenerating">
      <p class="font-bold mb-4">{{ t('pageTitle.generatingDishFromTitle', { dishTitle: selectedDishTitle?.name }) }}</p>
      <LoadingBar :durationInSeconds="18" />
    </div>

    <div v-if="!isGenerating">
      <div class="flex flex-col gap-2 mb-6">
        <AutoComplete v-model="searchQuery" :suggestions="dishTitles" :loading="isSearching" @complete="filterDishes"
          panel-class="hidden" :placeholder="t('common.searchPlaceHolder')" />
        <!-- <div class="flex flex-row gap-6">
            <div class="flex flex-row items-center gap-2">
              <Checkbox v-model="createdUsing" inputId="ia" name="ia" value="ia" @change="filterDishes" />
              <label for="ia">{{ t('profile.dishLists.iaGenerated') }}</label>
            </div>
            <div class="flex flex-row items-center gap-2">
              <Checkbox v-model="createdUsing" inputId="ocr" name="ocr" value="ocr" @change="filterDishes" />
              <label for="ocr">{{ t('profile.dishLists.ocrGenerated') }}</label>
            </div>
          </div> -->
      </div>
      <!-- <HistoryCard v-for="dish in dishes" :key="dish.id" :dish="dish" @card-clicked="handleCardClick(dish.id)"
          @handle-deletion="(wasDeleted: boolean) => handleDeletion(wasDeleted, dish.id)" @handle-duplication="(duplicationResponse: null | Dish) => handleDuplication(duplicationResponse, dish.id)
            " @handle-favorite-change="(favoriteResponse: boolean | null) => handleFavoriteChange(favoriteResponse, dish.id, true)
              " /> -->
      <!-- <TitleSuggestionButton :title="dish.name" @click=" confirmGenerateNewDish($event, dish,
          index, 'otherDishTitles' )" v-for="(dish, index) in dishTitles" :key="index" /> -->
      <div v-for="([date, dishes], groupIndex) in groupedDishesByDate" :key="groupIndex" class="mb-8">
        <h3 class="text-lg font-bold mb-4">{{ date }}</h3>
        <TitleSuggestionButton v-for="(dish, index) in dishes" :key="dish.id" :title="dish.name"
          @click="handleCardClick($event, dish, index, 'otherDishTitles')" />
      </div>
      <Button v-if="loadedDishes < totalDishes" @click="getDishes()"
        :loading="isFetchingList && dishTitles.length !== 0" :label="t('common.seeMore')" />
      <ConfirmDialog group="templating">
        <template #container="{ message, acceptCallback, rejectCallback }">
          <div class="flex flex-col p-5 rounded bg-white">
            <button @click="rejectCallback" class="absolute top-1 right-1 p-2 text-gray-600 hover:text-gray-900">
              <i class="pi pi-times"></i>
            </button>
            <span class="font-bold text-xl block mb-2 mt-2">{{ message.header }}</span>
            <p class="mb-4">{{ message.message }}</p>
            <div class="flex justify-end gap-2">
              <div class="flex justify-end gap-2">
                <!-- <Button :label="message.rejectLabel" outlined @click="rejectCallback"></Button> -->
                <Button :label="t('menuDishView.generateDishFromTitle.confirm')"
                  @click="handleGenerateDishFromTitle(rejectCallback)" severity="secondary"></Button>
                <Button :label="message.acceptLabel" @click="acceptCallback"></Button>
              </div>
            </div>
          </div>
        </template>
      </ConfirmDialog>
      <LoadingView v-if="isFetchingList && dishTitles.length === 0" />
      <AddDishTitleToPlanningDialog v-model:visible="showAddToPlanningDialog"
        @close-sidebar="showAddToPlanningDialog = false" />
    </div>
  </main>
</template>
