<script setup lang="ts">
import HistoryIcon from '@/assets/icons/Profile/HistoryIcon.vue'
import { computed, markRaw, onMounted, ref, type Ref } from 'vue'
import HeartEmpty from '@/assets/icons/2States/HeartEmpty.vue'
import ScanIcon from '@/assets/icons/Profile/ScanIcon.vue'
// import { useI18n } from 'vue-i18n'
// import type { MessageSchema } from '@/i18n/index.js'
import MyRecipesMenuItem from '@/components/MyRecipes/MyRecipesMenuItem.vue'
import PlanningIcon from '@/assets/icons/Planning/PlanningIcon.vue'
import GeneratedTitlesIcon from '@/assets/icons/Planning/GeneratedTitlesIcon.vue'
import Button from 'primevue/button'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import { watch } from 'vue'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import { useToast } from 'primevue/usetoast'
import { useDishesStore } from '@/stores/dishesStore'
import { useUserStore } from '@/stores/useUserStore'
import { useRoute, useRouter } from 'vue-router';
import Menu from 'primevue/menu'
import ButtonMenu from '@/assets/icons/Buttons/ButtonMenu.vue'
import { useConfirm } from 'primevue/useconfirm'
import Textarea from 'primevue/textarea'
import ButtonClose from '@/assets/icons/Buttons/ButtonClose.vue'
import ButtonConfirm from '@/assets/icons/Buttons/ButtonConfirm.vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const openDishTagModal: Ref<boolean> = ref(false)
const newTagName: Ref<string> = ref('');
// const myDishTags: Ref<DishTag[] | []> = ref([])
const { fetchData } = useFetch()
const envStore = useEnvStore()
const toast = useToast()
const user = ref(useUserStore().user)
const dishesStore = useDishesStore();
const fetchingTags = computed(() => dishesStore.fetchingTags);
const restaurantId = user.value?.restaurants[0]?.id || null
const route = useRoute();
const router = useRouter();
const parentId: Ref<string | string[] | null> = ref(route.params.parentId as string | null);
const confirmDialog = useConfirm();
const tagOptions = ref()
const isEditingTag = ref(false)

// Update parentId when route changes
watch(
  () => router.currentRoute.value.params.parentId,
  (newParentId) => {
    console.log('newParentId ', newParentId);
    parentId.value = newParentId || null;
  }
);

// Add computed property to check if current tag is 'uncategorized'
const isUncategorizedTag = computed(() => {
  const parentTag = dishesStore.tags.find(tag => tag.id.toString() === parentId.value);
  return parentTag?.name === 'uncategorized';
});

// Modify 'tagName' to translate 'uncategorized' tag name
const tagName = computed(() => {
  if (!parentId.value) return '';

  const currentTag = dishesStore.tags.find(tag => tag.id.toString() === parentId.value);
  if (!currentTag) return '';

  // Translate 'uncategorized' if needed
  const translatedCurrentName = currentTag.name === 'uncategorized'
    ? t('dishTag.uncategorized')
    : currentTag.name;

  if (currentTag.parent_dish_tag_id) {
    // If the current tag has a parent
    const parentTag = dishesStore.tags.find(tag => tag.id === currentTag.parent_dish_tag_id);
    if (parentTag) {
      const translatedParentName = parentTag.name === 'uncategorized'
        ? t('dishTag.uncategorized')
        : parentTag.name;
      return `${translatedParentName} > ${translatedCurrentName}`;
    }
  }

  // If there's no parent, just return the current tag's name
  return translatedCurrentName;
});


const links = [
  {
    name: t('pageTitle.myFavorites'),
    icon: null,
    url: '/my-recipes/favorites',
  },
]

const myCategories = computed(() => {
  if (parentId.value) {
    // If we have a parentId, find the parent tag and return its child tags
    const parentTag = dishesStore.tags.find(tag => tag.id.toString() === parentId.value);
    return (parentTag?.child_tags || []).map((tag) => ({
      name: tag.name === 'uncategorized' ? t('dishTag.uncategorized') : tag.name,
      icon: null,
      url: `/my-recipes/tags/${parentId.value}/${tag.id}`,
    }));
  }
  // If no parentId, return top-level tags
  return dishesStore.tags.map((tag) => ({
    name: tag.name,
    icon: null,
    url: parentId.value 
      ? `/my-recipes/tags/${parentId.value}/${tag.id}`
      : `/my-recipes/tags/${tag.id}`,
  }));
});


async function addDishTag() {
  if (newTagName.value.trim() !== '') {
    const newTag = {
      name: newTagName.value.trim(),
      parent_dish_tag_id: parentId.value ? parseInt(parentId.value.toString()) : null
    };

    try {
      const payload = {
        tags: [newTag],
      }
      const response = await fetchData(`${envStore.apiUrl}/dishes/tags/${restaurantId}`, 'POST', payload);

      if (response.data) {
        const createdTag = response.data.tags[0];
        
        if (createdTag.parent_dish_tag_id) {
          // If it's a child tag, find the parent and add to its child_tags
          const parentTag = dishesStore.tags.find(tag => tag.id === createdTag.parent_dish_tag_id);
          if (parentTag) {
            parentTag.child_tags = parentTag.child_tags || [];
            parentTag.child_tags.push(createdTag);
          }
        } else {
          // If it's a parent tag, add to root tags
          dishesStore.tags = [...dishesStore.tags, createdTag];
        }

        // toast.add({
        //   severity: 'success',
        //   summary: t('common.success'),
        //   detail: t('dishTag.add.success'),
        //   life: 3000,
        // });
      } else if (response.error) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: response.error,
          life: 3000,
        });
      }
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('dishTag.add.error'),
        life: 3000,
      });
    }
  }

  openDishTagModal.value = false;
}

const toggleTagOptions = (event: any) => {
  tagOptions.value.toggle(event)
}

const tagOptionsItem = ref([
  {
    items: [
      {
        label: t('menuDishView.topMenu.rename'),
        icon: 'pi pi-pencil',
        command: () => {
          newTagName.value = tagName.value
          isEditingTag.value = true
        },
        disabled: isUncategorizedTag.value,
      },
      {
        label: t('menuDishView.topMenu.delete'),
        icon: 'pi pi-trash',
        command: () => confirmDeleteTag(),
        disabled: isUncategorizedTag.value,
      },
    ],
  },
])

const confirmDeleteTag = () => {
  confirmDialog.require({
    message: t('dishTag.delete.confirmDelete'),
    header: t('dishTag.delete.confirmDelete'),
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined',
    rejectLabel: t('common.cancel'),
    acceptLabel: t('common.delete'),
    accept: () => {
      deleteTag();
    },
    reject: () => { }
  });
};

// Prevent deleting the 'uncategorized' tag
async function deleteTag() {
  if (!parentId.value) return;
  const tagToDelete = dishesStore.tags.find(tag => tag.id.toString() === parentId.value);
  if (!tagToDelete || tagToDelete.name === 'uncategorized') return;

  await dishesStore.deleteTag(parseInt(parentId.value.toString()), restaurantId);
  router.push('/my-recipes/tags');
}

// Prevent editing the 'uncategorized' tag
async function editTag() {
  if (!parentId.value) return;

  const tagToEdit = dishesStore.tags.find(tag => tag.id.toString() === parentId.value);
  if (!tagToEdit || tagToEdit.name === 'uncategorized') return;

  tagToEdit.name = newTagName.value;
  await dishesStore.editTag(tagToEdit, restaurantId);
  isEditingTag.value = false;
}

onMounted(async () => {
  if (dishesStore.tags.length === 0) {
    dishesStore.fetchTags(restaurantId, toast, parentId.value);
  }
})

watch(openDishTagModal, (newValue) => {
  if (!newValue) {
    newTagName.value = '';
  }
});

</script>

<template>
  <main>
    <div v-if="!parentId">
      <MyRecipesMenuItem v-for="(link, index) in links" :key="link.name" :name="link.name"
        :icon="link?.icon ?? undefined" :url="link.url" :index="index" />
    </div>
    <div v-else>
      <div v-if="!isEditingTag" class="flex justify-between gap-2 items-center">
        <div class="flex flex-row gap-2 items-center">
          <p class="font-bold my-4">{{ t('dishTag.label') }} :</p>
          <p>{{ tagName }}</p>
        </div>
        <Menu ref="tagOptions" id="overlay_menu" :model="tagOptionsItem" :popup="true"
          :pt="{ submenuHeader: { class: '!p-0' } }" :pt-options="{ mergeProps: true }" />
        <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
          @click="toggleTagOptions">
          <template #icon>
            <ButtonMenu />
          </template>
        </Button>
      </div>
      <div v-else class="flex justify-between gap-2 items-top">
        <Textarea class="w-full" row="2" v-model="newTagName" />
        <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
          @click="isEditingTag = false">
          <template #icon>
            <ButtonClose />
          </template>
        </Button>
        <Button link rounded :pt="{ root: { class: '!py-1.5 !px-0 !w-12 !h-8' } }" :pt-options="{ mergeProps: true }"
          @click="editTag">
          <template #icon>
            <ButtonConfirm />
          </template>
        </Button>
      </div>
    </div>
    <div class="my-7">
      <h1 class="text-2xl">{{ t('homeView.myRecipes.myCategories') }}</h1>
      <div class="mt-4">
        <p v-if="fetchingTags" class="mt-4 italic">{{ t('common.loading') }}</p>
        <p v-else-if="!fetchingTags && myCategories.length === 0" class="mt-4 italic">{{ t('dishTag.noTags') }}</p>
        <MyRecipesMenuItem v-else v-for="(link, index) in myCategories" :key="link.name" :name="link.name"
          :icon="link?.icon ?? undefined" :url="link.url" :index="index" />
      </div>
      <!-- <Button @click="null" :label="t('common.seeMore')" /> -->
      <div class="mt-4">
        <p @click="openDishTagModal = true" class="mb-10 inline-button">
          + {{ t('dishTag.add.label') }}
        </p>
      </div>
    </div>
    <Dialog v-model:visible="openDishTagModal" modal :header="t('dishTag.add.label')" :style="{ width: '25rem' }">
      <div v-if="openDishTagModal" class="flex items-center gap-4 mb-4">
        <label for="dishTag" class="font-semibold w-24">{{ t('dishTag.tagName') }}</label>
        <InputText id="dishTag" v-model="newTagName" class="flex-auto" autocomplete="off" />
      </div>
      <div class="flex justify-between gap-2">
        <Button type="button" :label="t('common.cancel')" outlined @click="openDishTagModal = false"
          :disabled="false"></Button>
        <div class="flex justify-end gap-2">
          <!-- <Button v-if="true" type="button"
            :label="t('common.generate')" @click="null" severity="secondary"
            :disabled="false"></Button> -->
          <Button type="button" :label="t('common.save')" @click="addDishTag()" :disabled="false"></Button>
        </div>
      </div>
    </Dialog>

  </main>
</template>
