import global from './global.js'
import autocomplete from './autocomplete'
import calendar from './calendar'
// import cascadeselect from './cascadeselect'
import checkbox from './checkbox'
// import chips from './chips'
// import colorpicker from './colorpicker'
// import floatlabel from './floatlabel'
// import iconfield from './iconfield'
// import inputgroup from './inputgroup'
// import inputotp from './inputotp'
// import inputgroupaddon from './inputgroupaddon'
// import inputmask from './inputmask'
import inputnumber from './inputnumber'
// import inputswitch from './inputswitch'
import inputtext from './inputtext'
// import knob from './knob'
// import listbox from './listbox'
import multiselect from './multiselect'
import password from './password'
import radiobutton from './radiobutton'
// import rating from './rating'
import selectbutton from './selectbutton'
import slider from './slider'
import textarea from './textarea'
// import togglebutton from './togglebutton'
// import treeselect from './treeselect'
// import tristatecheckbox from './tristatecheckbox'
import button from './button'
import speeddial from './speeddial'
// import splitbutton from './splitbutton'
import datatable from './datatable'
// import dataview from './dataview'
// import orderlist from './orderlist'
// import organizationchart from './organizationchart'
// import paginator from './paginator'
// import picklist from './picklist'
// import tree from './tree'
import treetable from './treetable'
// import timeline from './timeline'
import dropdown from './dropdown'
import accordion from './accordion'
// import card from './card'
// import deferred from './deferred'
import divider from './divider'
// import fieldset from './fieldset'
// import panel from './panel'
// import scrollpanel from './scrollpanel'
// import splitter from './splitter'
import stepper from './stepper'
import tabview from './tabview'
// import toolbar from './toolbar'
import confirmpopup from './confirmpopup'
import dialog from './dialog'
// import overlaypanel from './overlaypanel'
import sidebar from './sidebar'
// import tooltip from './tooltip'
// import fileupload from './fileupload'
// import breadcrumb from './breadcrumb'
// import contextmenu from './contextmenu'
// import dock from './dock'
import menu from './menu'
// import menubar from './menubar'
// import megamenu from './megamenu'
// import panelmenu from './panelmenu'
// import steps from './steps'
// import tabmenu from './tabmenu'
// import tieredmenu from './tieredmenu'
// import message from './message'
// import inlinemessage from './inlinemessage'
import toast from './toast'
// import carousel from './carousel'
// import galleria from './galleria'
// import image from './image'
// import avatar from './avatar'
// import badge from './badge'
// import badgedirective from './badgedirective'
// import blockui from './blockui'
import chip from './chip'
// import inplace from './inplace'
// import metergroup from './metergroup'
// import scrolltop from './scrolltop'
import skeleton from './skeleton'
import progressbar from './progressbar'
// import progressspinner from './progressspinner'
// import ripple from './ripple'
import tag from './tag'
// import terminal from './terminal'

export default {
  global,
  // directives: {
  //   tooltip,
  //   badgedirective,
  //   ripple,
  // },
  autocomplete,
  calendar,
  // cascadeselect,
  checkbox,
  // chips,
  // colorpicker,
  dropdown,
  // floatlabel,
  // iconfield,
  // inputgroup,
  // inputotp,
  // inputgroupaddon,
  // inputmask,
  inputnumber,
  // inputswitch,
  inputtext,
  // knob,
  // listbox,
  multiselect,
  password,
  radiobutton,
  // rating,
  selectbutton,
  slider,
  textarea,
  // togglebutton,
  // treeselect,
  // tristatecheckbox,
  button,
  speeddial,
  // splitbutton,
  datatable,
  // dataview,
  // orderlist,
  // organizationchart,
  // paginator,
  // picklist,
  // tree,
  treetable,
  // timeline,
  accordion,
  // card,
  // deferred,
  divider,
  // fieldset,
  // panel,
  // scrollpanel,
  // splitter,
  stepper,
  tabview,
  // toolbar,
  confirmpopup,
  dialog,
  // overlaypanel,
  sidebar,
  // fileupload,
  // breadcrumb,
  // contextmenu,
  // dock,
  menu,
  // menubar,
  // megamenu,
  // panelmenu,
  // steps,
  // tabmenu,
  // tieredmenu,
  // message,
  // inlinemessage,
  toast,
  // carousel,
  // galleria,
  // image,
  // avatar,
  // badge,
  // blockui,
  chip,
  // inplace,
  // metergroup,
  // scrolltop,
  skeleton,
  progressbar,
  // progressspinner,
  tag,
  // terminal,
}
