<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.4 21.6C1.74 21.6 1.1752 21.3652 0.7056 20.8956C0.236 20.426 0.0008 19.8608 0 19.2V4.80002C0 4.14002 0.2352 3.57522 0.7056 3.10562C1.176 2.63602 1.7408 2.40082 2.4 2.40002H21.6C22.26 2.40002 22.8252 2.63522 23.2956 3.10562C23.766 3.57602 24.0008 4.14082 24 4.80002V19.2C24 19.86 23.7652 20.4252 23.2956 20.8956C22.826 21.366 22.2608 21.6008 21.6 21.6H2.4ZM12 13.2L2.4 7.20002V19.2H21.6V7.20002L12 13.2ZM12 10.8L21.6 4.80002H2.4L12 10.8ZM2.4 7.20002V4.80002V19.2V7.20002Z"
      fill="currentColor"
    />
  </svg>
</template>
