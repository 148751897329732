<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4 7.88235H19.2V2H7.2V7.88235H6.264C5.604 7.16471 4.668 6.70588 3.6 6.70588C1.608 6.70588 0 8.28235 0 10.2353V18.4706C0 20.4235 1.608 22 3.6 22C4.668 22 5.604 21.5412 6.264 20.8235H24V11.4118C24 9.45882 22.392 7.88235 20.4 7.88235ZM4.8 18.4706C4.8 19.1176 4.26 19.6471 3.6 19.6471C2.94 19.6471 2.4 19.1176 2.4 18.4706V10.2353C2.4 9.58824 2.94 9.05882 3.6 9.05882C4.26 9.05882 4.8 9.58824 4.8 10.2353V18.4706ZM9.6 4.35294H16.8V7.88235H9.6V4.35294ZM21.6 18.4706H7.2V10.2353H20.4C21.06 10.2353 21.6 10.7647 21.6 11.4118V18.4706Z"
      fill="currentColor"
    />
    <path
      d="M15.6 13.7647C16.2627 13.7647 16.8 13.238 16.8 12.5882C16.8 11.9385 16.2627 11.4118 15.6 11.4118C14.9373 11.4118 14.4 11.9385 14.4 12.5882C14.4 13.238 14.9373 13.7647 15.6 13.7647Z"
      fill="currentColor"
    />
    <path
      d="M19.2 13.7647C19.8627 13.7647 20.4 13.238 20.4 12.5882C20.4 11.9385 19.8627 11.4118 19.2 11.4118C18.5373 11.4118 18 11.9385 18 12.5882C18 13.238 18.5373 13.7647 19.2 13.7647Z"
      fill="currentColor"
    />
    <path
      d="M15.6 17.2941C16.2627 17.2941 16.8 16.7674 16.8 16.1176C16.8 15.4679 16.2627 14.9412 15.6 14.9412C14.9373 14.9412 14.4 15.4679 14.4 16.1176C14.4 16.7674 14.9373 17.2941 15.6 17.2941Z"
      fill="currentColor"
    />
    <path
      d="M19.2 17.2941C19.8627 17.2941 20.4 16.7674 20.4 16.1176C20.4 15.4679 19.8627 14.9412 19.2 14.9412C18.5373 14.9412 18 15.4679 18 16.1176C18 16.7674 18.5373 17.2941 19.2 17.2941Z"
      fill="currentColor"
    />
    <path d="M13.2 11.4118H8.4V17.2941H13.2V11.4118Z" fill="currentColor" />
  </svg>
</template>
