<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3639 19.6407L13.6911 19.816L13.9536 19.5536L16.8869 16.6202C16.9907 16.5164 17.0746 16.4584 17.1531 16.4236C17.2293 16.3897 17.325 16.3667 17.4667 16.3667H17.7796C19.2902 16.9025 21.0472 17.1667 22.6667 17.1667C22.8374 17.1667 23.05 17.2571 23.2298 17.4369C23.4096 17.6167 23.5 17.8293 23.5 18V22.6667C23.5 22.8374 23.4096 23.05 23.2298 23.2298C23.05 23.4096 22.8374 23.5 22.6667 23.5C10.4095 23.5 0.5 13.5905 0.5 1.33333C0.5 1.16262 0.590393 0.950047 0.77022 0.77022C0.950047 0.590393 1.16262 0.5 1.33333 0.5H6C6.17072 0.5 6.38329 0.590393 6.56311 0.77022C6.74294 0.950047 6.83333 1.16262 6.83333 1.33333C6.83333 2.98348 7.10753 4.76123 7.51762 6.26489L7.53897 6.34317L7.58398 6.41068C7.66848 6.53744 7.68431 6.80859 7.37978 7.11311L4.44645 10.0464L4.19162 10.3013L4.35279 10.6236C6.27164 14.4613 9.55194 17.5986 13.3639 19.6407ZM2.66667 2.16667H2.12673L2.16814 2.70501C2.30525 4.48746 2.71568 6.26151 3.25899 7.89145L3.51483 8.65895L4.08689 8.08689L5.68689 6.48689L5.88707 6.28671L5.8184 6.01207C5.55354 4.95259 5.29323 3.77808 5.16361 2.61145L5.11419 2.16667H4.66667H2.66667ZM21.295 21.8319L21.8333 21.8733V21.3333V19.2V18.7H21.3333C20.1999 18.7 19.0517 18.4475 17.9879 18.1816L17.7133 18.1129L17.5131 18.3131L15.9131 19.9131L15.3411 20.4852L16.1086 20.741C17.7385 21.2843 19.5125 21.6947 21.295 21.8319Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
</template>
